// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IComInput {
  label: string
  icon?: any
  value?: any
  setValue?: any
  placeholder?: string
  type?: string
  onEnter?: Function
  params?: any
}

function ComInput({ label, icon, value, setValue, placeholder, type, onEnter = () => {}, params = {} }: IComInput) {
  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter") {
      onEnter()
    }
  }

  return (
    <div className="flex flex-col mb-5 w-full">
      <label style={{fontFamily:"Montserrat"}} className="mb-1 font-Montserrat tracking-wide text-gray-600">{label}</label>
      <div className="relative">
        <input
        style={{fontFamily:"monospace"}}
          name={label}
          className={`text-sm
                    placeholder-gray-500
                    pr-4
                    ${icon ? "pl-10" : "pl-4"}
                    rounded-md
                    border border-gray-200
                    w-full
                    py-2
                    focus:outline-none focus:border-primCol1-400`}
          type={type ? type : "text"}
          placeholder={placeholder ? placeholder : `Enter your ${String(label).toLowerCase()}`}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleKeyDown}
          {...params}
        />
      </div>
    </div>
  )
}

export default ComInput
