import { v4 as uuidv4 } from "uuid"
export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

export const  generateBlobUrl = async (imageUrl: string) =>  {
    const blob = await (await fetch(imageUrl)).blob()
    return URL.createObjectURL(blob)
}

export const  getCanvasObjectTemplate = (obj: any) =>  {
  let fName = obj.pageURL.split("/")
  fName = fName[fName.length - 2]
  return {
    height: obj.imageHeight,
    width: obj.imageWidth,
    id: uuidv4(),
    fpath: obj.largeImageURL,
    filename: fName,
    preview: obj.previewURL,
  }
}