function FlipHorizontal({ size }: { size: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24">
      <path
        d="M9.93729 3L3.20154 17.8193C3.04582 18.162 2.97858 18.5383 3.00596 18.9137C3.03335 19.2891 3.15449 19.6517 3.35829 19.9682C3.56209 20.2847 3.84204 20.545 4.1725 20.7252C4.50295 20.9055 4.87336 21 5.24979 21H11.1703V3.7845L11.165 3H9.93729ZM9.67029 19.5H5.24979C5.12424 19.5001 5.00067 19.4687 4.89042 19.4086C4.78017 19.3485 4.68676 19.2617 4.61877 19.1562C4.55077 19.0507 4.51035 18.9297 4.50123 18.8045C4.4921 18.6793 4.51456 18.5538 4.56654 18.4395L9.67029 7.212V19.5Z"
        fill="currentColor"
      />
      <path
        d="M20.6389 17.8193L14.1177 3.471L13.8897 3H12.6702V21H18.5907C18.9671 21 19.3375 20.9055 19.668 20.7252C19.9984 20.545 20.2784 20.2847 20.4822 19.9682C20.686 19.6517 20.8071 19.2891 20.8345 18.9137C20.8619 18.5383 20.7946 18.162 20.6389 17.8193Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default FlipHorizontal
