import { useStyletron, styled } from "baseui"
import { BASE_ITEMS } from "~/constants/app-options"
import useAppContext from "~/hooks/useAppContext"
import Icons from "~/components/Icons"
import { useTranslation } from "react-i18next"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import Scrollable from "~/components/Scrollable"
import { Block } from "baseui/block"

const Container = styled("div", (props) => ({
  width: "80px",

  display: "flex",
}))

const PanelsList = () => {
  const { activePanel } = useAppContext()
  const { t } = useTranslation("editor")
  const PANEL_ITEMS = BASE_ITEMS
  return (
    <Container className="bg-[#D14DED]">
      <Scrollable autoHide={true}>
        {PANEL_ITEMS.map((panelListItem) => (
          <PanelListItem
            label={t(`panels.panelsList.${panelListItem.id}`)}
            name={panelListItem.name}
            key={panelListItem.name}
            icon={panelListItem.name}
            activePanel={activePanel}
          />
        ))}
      </Scrollable>
    </Container>
  )
}

const PanelListItem = ({ label, icon, activePanel, name }: any) => {
  const { setActivePanel } = useAppContext()
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const [css, theme] = useStyletron()
  icon = icon.split(" ").join("")
  // @ts-ignore
  const Icon = Icons[icon]
  return (
    <Block
      id="EditorPanelList"
      onClick={() => {
        setIsSidebarOpen(true)
        setActivePanel(name)
      }}
      $style={{
        width: "80px",
        height: "80px",
        backgroundColor: name === activePanel ? "#984BF5" : "#D14DED",
        color: theme.colors.white,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "0.7rem",
        userSelect: "none",
        transition: "all 0.5s",
        gap: "0.1rem",
        ":hover": {
          cursor: "pointer",
          backgroundColor: "#944BF7",
          transition: "all 1s",
        },
      }}
      className="my-0.5"
    >
      <Icon size={24} />
      {label == "Canvas Background" ? (
        <div className="">
          <div className="flex justify-center items-center ">Canvas</div>
          <div className="flex justify-center items-center">Background</div>
        </div>
      ) : label == "Element Themes" ? (
        <div className="">
          <div className="flex justify-center items-center ">Element</div>
          <div className="flex justify-center items-center">Themes</div>
        </div>
      ) : label == "Letters And Numbers" ? (
        <div className="">
          <div className="flex justify-center items-center ">Letters &</div>
          <div className="flex justify-center items-center">Numbers</div>
        </div>
      ) : label == "9,250 Elements" ? (
        <div className="">
          <div className="flex justify-center items-center ">9,250</div>
          <div className="flex justify-center items-center">Elements</div>
        </div>
      ) : label == "Upload Elements" ? (
        <div className="">
          <div className="flex justify-center items-center ">Upload</div>
          <div className="flex justify-center items-center">Elements</div>
        </div>
      ) : (
        <div className="flex justify-center items-center flex-nowrap">{label}</div>
      )}
    </Block>
  )
}

export default PanelsList
