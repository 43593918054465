import React from "react"
import { useStyletron } from "baseui"
import Add from "~/components/Icons/Add"
import useDesignEditorPages from "~/hooks/useDesignEditorScenes"
import { DesignEditorContext } from "~/contexts/DesignEditor"
import { nanoid } from "nanoid"
import { getDefaultTemplate } from "~/constants/design-editor"
import { useEditor, useFrame } from "@layerhub-io/react"
import { IScene } from "@layerhub-io/types"
import { DndContext, closestCenter, PointerSensor, useSensor, DragOverlay } from "@dnd-kit/core"
import { arrayMove, SortableContext, horizontalListSortingStrategy } from "@dnd-kit/sortable"
import { restrictToFirstScrollableAncestor, restrictToHorizontalAxis } from "@dnd-kit/modifiers"
import SceneItem from "./SceneItem"
import { Block } from "baseui/block"
import useContextMenuTimelineRequest from "~/hooks/useContextMenuTimelineRequest"
import SceneContextMenu from "./SceneContextMenu"
import toPX from "to-px"
import { scenesClassDB, uploadedImagesClassDB } from "~/indexDB/db"
import { useDispatch } from "react-redux"
import { openSnackBar } from "~/store/slices/snackbar/actions"
import { setLoadingStatus } from "~/store/slices/loading-status/actions"
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip"
import { getSyncDataByUserId, syncDataByUserId } from "~/services/digital-assets"
import * as jsonpack from "jsonpack/main"

const Scenes = () => {
  const scenes = useDesignEditorPages()
  const { setScenes, setCurrentScene, currentScene, setCurrentDesign, currentDesign } =
    React.useContext(DesignEditorContext)
  const editor = useEditor()
  const [css] = useStyletron()
  const [currentPreview, setCurrentPreview] = React.useState("")
  const frame = useFrame()
  const [draggedScene, setDraggedScene] = React.useState<IScene | null>(null)
  const contextMenuTimelineRequest = useContextMenuTimelineRequest()
  const dispatch = useDispatch()

  const sensors = [
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  ]

  React.useEffect(() => {
    if (editor && scenes && currentScene) {
      const isCurrentSceneLoaded = scenes.find((s) => s.id === currentScene?.id)
      if (!isCurrentSceneLoaded) {
        setCurrentScene(scenes[0])
      }
    }
  }, [editor, scenes, currentScene])

  React.useEffect(() => {
    let watcher = async () => {
      const updatedTemplate = editor.scene.exportToJSON()
      const updatedPreview = (await editor.renderer.render(updatedTemplate)) as string
      setCurrentPreview(updatedPreview)
      // await updateCurrentSceneAndSave({ ...updatedTemplate, preview: updatedPreview })

      // legacy code for saving scenes
      // let updatedPages = scenes
      //   .map((p) => {
      //     if (p.id === updatedTemplate.id) {
      //       return { ...updatedTemplate, preview: updatedPreview }
      //     }
      //     return p
      //   })
      //   .map((p) => {
      //     const { ["preview"]: _, ...rest } = p
      //     return rest
      //   })
      // updatedPages = jsonpack.pack(updatedPages)
      // const count = await scenesClassDB.scenes.count()

      // try {
      //   if (count) {
      //     await scenesClassDB.scenes.update(1, {
      //       scenes: updatedPages,
      //       currentScenes: null,
      //     })
      //   } else {
      //     await scenesClassDB.scenes.add({
      //       scenes: updatedPages,
      //       currentScenes: null,
      //     })
      //   }
      // } catch (error) {
      //   console.error(error)
      //   dispatch(
      //     openSnackBar({
      //       title: "Error saving scenes",
      //       message: "Unable to save scenes",
      //       KIND: "error",
      //     })
      //   )
      // }
    }
    if (editor) {
      editor.on("history:changed", watcher)
    }
    return () => {
      if (editor) {
        editor.off("history:changed", watcher)
      }
    }
  }, [editor, scenes, currentScene])

  React.useEffect(() => {
    if (editor) {
      setPrevData()
    }

    return () => {
      // console.warn("unmount")
    }
  }, [editor])

  React.useEffect(() => {
    if (editor) {
      if (currentScene) {
        updateCurrentScene(currentScene)
      } else {
        const defaultTemplate = getDefaultTemplate({
          width: toPX("17.29in") || 1200,
          height: toPX("25.66in") || 1200,
        })
        setCurrentDesign({
          id: nanoid(),
          frame: defaultTemplate.frame,
          metadata: {},
          name: "Untitled Design Page",
          preview: "",
          scenes: [],
          type: "PRESENTATION",
        })
        editor.scene
          .importFromJSON(defaultTemplate)
          .then(() => {
            const initialDesign = editor.scene.exportToJSON() as any
            editor.renderer.render(initialDesign).then((data) => {
              setCurrentScene({ ...initialDesign, preview: data })
              setScenes([{ ...initialDesign, preview: data }])
            })
          })
          .catch(console.error)
      }
    }
  }, [editor, currentScene])

  const updateCurrentScene = React.useCallback(
    async (design: IScene) => {
      await editor.scene.importFromJSON(design)
      const updatedPreview = (await editor.renderer.render(design)) as string
      setCurrentPreview(updatedPreview)
    },
    [editor, currentScene]
  )

  const setPrevData = async () => {
    dispatch(
      setLoadingStatus({
        isLoading: true,
        title: "Loading scenes...",
      })
    )

    setTimeout(async () => {
      dispatch(
        setLoadingStatus({
          isLoading: false,
          title: "",
        })
      )
    }, 1000 * 60)

    let localImages: any = null
    let prevData: any = null
    let localData: any = null
    let scenes: any = false

    try {
      const token = sessionStorage.getItem("token")
      localImages = await uploadedImagesClassDB.images.get(1)

      if (token) {
        localData = await getSyncDataByUserId({ token })
      }
    } catch (error: any) {
      if (error && error.message && error.message === "Network Error") {
        dispatch(
          openSnackBar({
            title: "Network Error",
            message: "Unable to connect to server",
            KIND: "error",
          })
        )
      } else if (error && error.response && error.response.status === 401) {
        dispatch(
          openSnackBar({
            title: "Unauthorized",
            message: "Please login again",
            KIND: "error",
          })
        )
      } else if (error && error.response && error.response.status === 500) {
        dispatch(
          openSnackBar({
            title: "Server Error",
            message: "Unable to connect to server",
            KIND: "error",
          })
        )
      } else if (error && error.message) {
        dispatch(
          openSnackBar({
            title: "Error loading scenes",
            message: error.message,
            KIND: "error",
          })
        )
      } else {
        dispatch(
          openSnackBar({
            title: "Error loading scenes",
            message: "Unable to download data from server",
            KIND: "error",
          })
        )
      }
    }

    if (localData && localData.length > 0) {
      scenes = localData
    } else {
      // legacy code for local storage
      prevData = await scenesClassDB.scenes.get(1)

      let unpackedScenes = null
      try {
        unpackedScenes = jsonpack.unpack(prevData.scenes)

        if (unpackedScenes) {
          // console.warn("unpacked scenes")
          prevData.scenes = unpackedScenes

          scenes = prevData.scenes
        }
      } catch (error) {
        try {
          scenes = prevData.scenes
        } catch (error) {
          console.error("Unable to set localdata")
          console.error(error)
        }
        console.error(error)
      }
    }

    if (scenes) {
      try {
        for (let index = 0; index < scenes.length; index++) {
          const element = scenes[index]
          let spliceIndex: number[] = []
          for (let i: number = 0; i < element.layers.length; i++) {
            const l = element.layers[i]

            if (l.type === "StaticImage" && !l.hasOwnProperty("src")) {
              let localImage = await localImages?.images.find((i: any) => i.id === l.id)
              if (localImage) {
                l["src"] = localImage.src
                l["preview"] = localImage.src
              } else {
                spliceIndex.push(i)
              }
            }

            for (let index in spliceIndex) {
              element.layers.splice(spliceIndex[index], 1)
            }
          }

          try {
            //support for legacy
            if (!scenes[index].hasOwnProperty("preview")) {
              const updatedPreview = (await editor.renderer.render(scenes[index])) as string
              scenes[index] = {...element, preview: updatedPreview}
            }
          } catch (error) {
            console.error("error rendering")
            console.error(error)
          }
          // console.log(scenes[index])
        }
        setScenes(scenes)
      } catch (error) {
        console.error(error)
        dispatch(
          openSnackBar({
            title: "Error loading scenes",
            message: "Unable to load scenes",
            KIND: "error",
          })
        )
      }

      // setCurrentScene(prevData.currentScenes)
    }
    dispatch(
      setLoadingStatus({
        isLoading: false,
        title: "",
      })
    )
  }

  const syncData = async ({ updatedScenes }: { updatedScenes: IScene[] }) => {
    const token = sessionStorage.getItem("token")

    if (token) {
      const updatedPages = updatedScenes.map((p) => {
        const { ["preview"]: _, ...rest } = p
        return rest
      })

      syncDataByUserId({
        token,
        data: updatedPages,
      })
        .then((res) => {})
        .catch((err) => {
          console.error(err)
          dispatch(
            openSnackBar({
              title: "Error syncing scenes",
              message: "Unable to sync scenes",
              KIND: "error",
            })
          )
        })
    }
  }

  const addScene = React.useCallback(async () => {
    setCurrentPreview("")
    const updatedTemplate = editor.scene.exportToJSON()
    const updatedPreview = await editor.renderer.render(updatedTemplate)

    const updatedPages = scenes.map((p) => {
      if (p.id === updatedTemplate.id) {
        return { ...updatedTemplate, preview: updatedPreview }
      }
      return p
    })

    const defaultTemplate = getDefaultTemplate(currentDesign.frame)
    console.log(defaultTemplate)
    const newPreview = await editor.renderer.render(defaultTemplate)
    const newPage = { ...defaultTemplate, id: nanoid(), preview: newPreview } as any
    const newPages = [...updatedPages, newPage] as any[]
    setScenes(newPages)
    setCurrentScene(newPage)

    syncData({ updatedScenes: newPages })

    const TimelineItemsContainer2 = document.getElementById("TimelineItemsContainer")
    TimelineItemsContainer2?.scrollTo({
      top: 0,
      left: TimelineItemsContainer2.scrollWidth + 100,
      behavior: "smooth",
    })

    // await saveDataOnPageAdd(updatedPages, newPage)
  }, [scenes, currentDesign])

  const changePage = React.useCallback(
    async (page: any) => {
      setCurrentPreview("")
      if (editor) {
        const updatedTemplate = editor.scene.exportToJSON()
        const updatedPreview = await editor.renderer.render(updatedTemplate)

        const updatedPages = scenes.map((p) => {
          if (p.id === updatedTemplate.id) {
            return { ...updatedTemplate, preview: updatedPreview }
          }
          return p
        }) as any[]

        setScenes(updatedPages)
        setCurrentScene(page)
      }
    },
    [editor, scenes, currentScene]
  )

  const handleDragStart = (event: any) => {
    const draggedScene = scenes.find((s) => s.id === event.active.id)
    if (draggedScene) {
      setDraggedScene(draggedScene)
    }
  }

  const handleDragEnd = (event: any) => {
    const { active, over } = event

    if (active.id !== over.id) {
      setScenes((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id)
        const newIndex = items.findIndex((item) => item.id === over.id)

        return arrayMove(items, oldIndex, newIndex)
      })
    }
    setDraggedScene(null)
  }

  return (
    <DndContext
      modifiers={[restrictToFirstScrollableAncestor, restrictToHorizontalAxis]}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      {/* <Block
        id="TimelineItemsContainer"
        $style={{ padding: "0.25rem 0.25rem", background: "#ffffff", position: "relative" }}
      > */}
      <Block
        className={css({ display: "flex", alignItems: "center", overflowX: "auto", width: "100%" })}
        id="TimelineItemsContainer"
      >
        {contextMenuTimelineRequest.visible && <SceneContextMenu />}
        <SortableContext items={scenes} strategy={horizontalListSortingStrategy}>
          {scenes.map((page, index) => (
            <SceneItem
              key={index}
              isCurrentScene={page.id === currentScene?.id}
              scene={page}
              index={index}
              changePage={changePage}
              preview={
                currentPreview && page.id === currentScene?.id ? currentPreview : page.preview ? page.preview : ""
              }
            />
          ))}
        </SortableContext>

        <StatefulTooltip
          content="Add Page"
          placement={PLACEMENT.rightTop}
          showArrow={true}
          accessibilityType={"tooltip"}
        >
          <Block
            style={{
              background: "#ffffff",

              // top, right, bottom, left
              margin: "0.2rem",
              overflow: "hidden",
              minWidth: "70px",
              marginRight: "2rem",
            }}
          >
            <div
              onClick={addScene}
              className={css({
                width: "70px",
                height: "56px",
                background: "rgb(243,244,246)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              })}
            >
              <Add size={20} />
            </div>
          </Block>
        </StatefulTooltip>
        <DragOverlay>
          {draggedScene ? (
            <Block
              $style={{
                backgroundImage: `url(${draggedScene.preview})`,
                backgroundSize: `${frame ? (frame.width * 70) / frame.height : 70}px 70px`,
                height: "80px",
                opacity: 0.75,
              }}
            />
          ) : null}
        </DragOverlay>
        <div className="mr-14"></div>
      </Block>
      {/* </Block> */}
    </DndContext>
  )
}

export default Scenes
