import { useState } from "react"
import ComBG from "../../components/common/Bg"
import ComButton from "../../components/common/Button1"
import ComInput from "../../components/common/Input"
import LogInLogo from "~/components/Icons/Logo"
import { ILoginResponse, login } from "~/services/auth"
import LoginLogo from "~/components/Icons/LoginPageLogo"


function Login() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState<any>("")
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)


  async function loginUser() {
    setLoading(true)
    setError(false)

    if (loading) {
      return false
    }
    try {
      let result: ILoginResponse = await login({ username: email, password })
      // // console.log(result.data.error.full)
      if (result.token) {
        sessionStorage.setItem("token", result.token)
        window.location.href = "/"
      } else {

        setShowError(true)
        if(result && result.Error){
        setError(result.Error)
        }
        else{
          setError("Something went wrong")
        }
      }
    } catch (e: any) {
      console.log(e)
      setError(e.message)
    }
    setLoading(false)
  }

  return (
    <div>
      <div className="fixed w-screen h-screen overflow-hidden">
        <ComBG className="w-screen" />
      </div>

      <main className="bg-gradient-to-r from-primCol1-400 to-secCol1-300 relative w-screen h-screen flex flex-col items-center">
        <div className="relative flex flex-col rounded-xl bg-white p-5 md:rounded-xl w-full sm:w-1/2 lg:w-1/3 my-auto py-14 transform scale-90 shadow-lg">
          <h1 className="text-3xl text-center font-bold mx-auto">
            {/* <LoginLogo size={75} /> */}
            SELF PUBLISHING TITANS
          </h1>

          <br />
          <hr />

          <br />

          <ComInput icon={"user"} label="Email" value={email} setValue={setEmail} />
          <ComInput
            icon={"key"}
            label="Password"
            value={password}
            setValue={setPassword}
            onEnter={loginUser}
            type="password"
          />

          <ComButton
            text={loading ? <div className="h-3 w-3 font-mono bg-primCol1-100 animate-bounce mx-auto m-1" /> : "Login"}
            action={loginUser}
          />
          {error && (
            <p className={`${showError ? "block" : "hidden"} text-center text-primCol1-700 text-base`}>{error}</p>
          )}
          <div className="flex justify-center">
            <a href={`https://www.selfpublishingtitans.com/auth/forgot`} className="text-sm text-secCol1-700">
              Forgot Password?
            </a>
          </div>
          <br />
          <a href="https://www.selfpublishingtitans.com/auth/register" className="text-primary text-center">
            Create A New Account
          </a>
          <br />
          <p className="text-sm text-center mt-auto mb-5">All Rights Reserved 2021</p>
        </div>
      </main>
    </div>
  )
}


export default Login
