import React, { useEffect } from "react"
import { styled, ThemeProvider, DarkTheme } from "baseui"
import { Theme } from "baseui/theme"
import { Button, KIND } from "baseui/button"
import Logo from "~/components/Icons/Logo"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import { Block } from "baseui/block"
import { useEditor } from "@layerhub-io/react"
import useEditorType from "~/hooks/useEditorType"
import { IScene } from "@layerhub-io/types"
import { loadTemplateFonts } from "~/utils/fonts"
import { loadVideoEditorAssets } from "~/utils/video"
import DesignTitle from "./DesignTitle"
import { IDesign } from "~/interfaces/DesignEditor"
import { selectFrameSize } from "~/store/slices/current-frame-size/selectors"
import { useDispatch, useSelector } from "react-redux"
import jsPDF from "jspdf"
import { authSelector, isOwnerSelector } from "~/store/slices/auth/selectors"
import { setAuth } from "~/store/slices/auth/actions"
import { setLoadingStatus } from "~/store/slices/loading-status/actions"
import { useState } from "react"
import { Modal, ROLE } from "baseui/modal"
import JSZip from "jszip"
import axios from "axios"

const Container = styled<"div", {}, Theme>("div", ({ $theme }) => ({
  height: "64px",
  display: "grid",
  padding: "0 1.25rem",
  gridTemplateColumns: "380px 1fr 2fr",
  alignItems: "center",
}))

const Navbar = () => {
  const { setDisplayPreview, setScenes, setCurrentDesign, setCurrentScene, currentDesign, currentScene, scenes } =
    useDesignEditorContext()
  const editorType = useEditorType()
  const editor = useEditor()
  // console.log("editor is",editor)
  const [fileName, setFileName] = useState("My Letters")
  const [fileFormat, setFileFormat] = useState("zip")
  const currentSize = useSelector(selectFrameSize).currentSize

  const inputFileRef = React.useRef<HTMLInputElement>(null)
  // const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const isAuthenticated = useSelector(authSelector).isAuthenticated
  const isOwner = useSelector(isOwnerSelector).isOwner
  const isOwnerDirty = useSelector(isOwnerSelector).isDirty
  const dispatch = useDispatch()
  const zip = new JSZip()
  const [isOpen, setIsOpen] = useState(false)
  const alertText = "Need to have more than 1 coloring element to ensure quality and avoid duplicate content"

  const downloadFormats = {
    png: "png",
    pdf: "pdf",
  }

  const handleDownload = async () => {
    console.log(fileFormat)
    if (fileName.length == 0) return alert("file name should not be empty")
    if (fileFormat == downloadFormats.pdf) {
      flatPdf()
    } else {
      downloadZip()
    }
  }

  const flatPdf = async () => {
    if (!isOwner) {
      alert("To download pdf, one needs to purchase the Digital Titans Designer  first.")
      return
    }
    dispatch(
      setLoadingStatus({
        isLoading: true,
        title: "Preparing PDF...",
      })
    )

    editor.frame.frame.canvas.isDrawingMode = false
    editor.cancelContextMenuRequest()
    const currentScene = editor.scene.exportToJSON()

    const pdfDoc = new jsPDF({
      unit: "in",
      format: [currentSize.width, currentSize.height],
    })

    for (let i = 0; i < scenes.length; i++) {
      const scn = scenes[i]
      if (i === 0) {
        // add first page
        if (scn.id === currentScene.id) {
          const preview = await editor.renderer.toDataURL(currentScene, {})

          // add new page
          pdfDoc.addImage(
            preview as string,
            0,
            0,
            pdfDoc.internal.pageSize.getWidth(),
            pdfDoc.internal.pageSize.getHeight(),
            undefined,
            "FAST"
          )
        } else {
          pdfDoc.addImage(
            scn.preview as string,
            0,
            0,
            pdfDoc.internal.pageSize.getWidth(),
            pdfDoc.internal.pageSize.getHeight(),
            undefined,
            "FAST"
          )
        }
      } else {
        pdfDoc.addPage()
        if (scn.id === currentScene.id) {
          const preview = await editor.renderer.toDataURL(currentScene, {})

          pdfDoc.addImage(
            preview as string,
            0,
            0,
            pdfDoc.internal.pageSize.getWidth(),
            pdfDoc.internal.pageSize.getHeight(),
            undefined,
            "FAST"
          )
        } else {
          pdfDoc.addImage(
            scn.preview as string,
            0,
            0,
            pdfDoc.internal.pageSize.getWidth(),
            pdfDoc.internal.pageSize.getHeight(),
            undefined,
            "FAST"
          )
        }
      }
    }

    if (currentDesign) {
      pdfDoc.save(`${fileName}.pdf`)
    } else {
      console.log("NO CURRENT DESIGN")
    }
    dispatch(
      setLoadingStatus({
        isLoading: false,
        title: "",
      })
    )

    setIsOpen(false)
  }

  const downloadZip = async () => {
    //update scenes here to download
    editor.history.save()
    editor.cancelContextMenuRequest()
    editor.canvas.requestRenderAll()
    //
    if (!isOwner) {
      alert("To download pdf, one needs to purchase the Digital Titans Designer first.")
      return
    }
    dispatch(
      setLoadingStatus({
        isLoading: true,
        title: "Preparing Zip...",
      })
    )
    const folder = zip.folder("images")

    //increasing the resolution of the image
    const dataUriToBlob = (dataURI: any, dpi: number) => {
      return new Promise((resolve) => {
        const img = new Image()
        img.src = dataURI
        img.onload = () => {
          const canvas = document.createElement("canvas")
          canvas.width = (img.width / 96 ) * dpi 
          canvas.height = (img.height / 96) *dpi 
          const ctx = canvas.getContext("2d")
          ctx?.drawImage(img, 0, 0, canvas.width, canvas.height)
          canvas.toBlob((blob) => {
            resolve(blob)
          }, "image/png")
        }
      })
    }

    for (let i = 0; i < scenes.length; i++) {
      const scn = scenes[i]
      let pngImage;
        pngImage = scn.preview
      //@ts-ignore
      const dpiAdjustedBlob = await dataUriToBlob(pngImage, 300)
      //@ts-ignore
      folder.file(`image_${i + 1}.png`, dpiAdjustedBlob)
    }
    zip.generateAsync({ type: "blob" }).then((content) => {
      const downloadLink = document.createElement("a")
      downloadLink.href = URL.createObjectURL(content)
      downloadLink.download = `${fileName}.zip`
      downloadLink.click()
    })

    dispatch(
      setLoadingStatus({
        isLoading: false,
        title: "",
      })
    )
    setIsOpen(false)
  }

  const loadGraphicTemplate = async (payload: IDesign) => {
    const scenes = []
    const { scenes: scns, ...design } = payload

    for (const scn of scns) {
      const scene: IScene = {
        name: scn.name,
        frame: payload.frame,
        id: scn.id,
        layers: scn.layers,
        metadata: {},
      }
      const loadedScene = await loadVideoEditorAssets(scene)
      await loadTemplateFonts(loadedScene)

      const preview = (await editor.renderer.render(loadedScene)) as string
      scenes.push({ ...loadedScene, preview })
    }

    return { scenes, design }
  }

  const loadPresentationTemplate = async (payload: IDesign) => {
    const scenes = []
    const { scenes: scns, ...design } = payload

    for (const scn of scns) {
      const scene: IScene = {
        name: scn.name,
        frame: payload.frame,
        id: scn,
        layers: scn.layers,
        metadata: {},
      }
      const loadedScene = await loadVideoEditorAssets(scene)

      const preview = (await editor.renderer.render(loadedScene)) as string
      await loadTemplateFonts(loadedScene)
      scenes.push({ ...loadedScene, preview })
    }
    return { scenes, design }
  }

  const loadVideoTemplate = async (payload: IDesign) => {
    const scenes = []
    const { scenes: scns, ...design } = payload

    for (const scn of scns) {
      const design: IScene = {
        name: "Awesome template",
        frame: payload.frame,
        id: scn.id,
        layers: scn.layers,
        metadata: {},
        duration: scn.duration,
      }
      const loadedScene = await loadVideoEditorAssets(design)

      const preview = (await editor.renderer.render(loadedScene)) as string
      await loadTemplateFonts(loadedScene)
      scenes.push({ ...loadedScene, preview })
    }
    return { scenes, design }
  }

  const handleImportTemplate = React.useCallback(
    async (data: any) => {
      let template
      if (data.type === "GRAPHIC") {
        template = await loadGraphicTemplate(data)
      } else if (data.type === "PRESENTATION") {
        template = await loadPresentationTemplate(data)
      } else if (data.type === "VIDEO") {
        template = await loadVideoTemplate(data)
      }
      //   @ts-ignore
      setScenes(template.scenes)
      //   @ts-ignore
      setCurrentDesign(template.design)
    },
    [editor]
  )

  const handleLogout = () => {
    sessionStorage.removeItem("token")
    dispatch(
      setAuth({
        isAuthenticated: false,
        user: null,
        token: null,
      })
    )
    window.location.href = "/login"
  }

  useEffect(() => {
    if (editor) {
      if (!isOwner && isOwnerDirty) {
        fetch("/data/template.json").then((res) => {
          res.json().then(async (data) => {
            if (editor.scene.exportToJSON().layers.length <= 1) {
              handleImportTemplate(data)
            }
          })
        })

        // console.log("NOT OWNER")
      }
    }
  }, [editor, isOwner, isOwnerDirty])

  return (
    // @ts-ignore
    <>
      {" "}
      <ThemeProvider theme={DarkTheme}>
        <Container className="themeGradient">
          <div className="relative h-auto">
            <a
              style={{ color: "#ffffff" }}
              href="https://www.selfpublishingtitans.com/"
              className="cursor-pointer flex items-center font-bold"
            >
              {!isOwner && <h1 className="absolute font-bold top-0 right-0">Preview</h1>}
              <div className="flex w-12 ">
                <Logo size={10} />
              </div>
              <h2 className=" ml-2 text-2xl">Digital Titans Designer</h2>
            </a>
          </div>

          <DesignTitle />
          <Block $style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            {isOwner ? (
              <Button size="compact" onClick={() => setIsOpen(true)} kind={KIND.tertiary}>
                Download
              </Button>
            ) : (
              <Button
                size="compact"
                onClick={() => {
                  window.open("http://www.selfpublishingtitans.com/coloring-book-maker", "_self")
                }}
                kind={KIND.tertiary}
              >
                Buy Now
              </Button>
            )}
            {/* <Button
              size="compact"
              onClick={() => {
                window.open(
                  "https://docs.google.com/document/d/13vFaJ7YousbC6Zy77xcVY2VgSCVhhr3dI1cpKntycro/edit?usp=sharing",
                  "_blank"
                )
              }}
              kind={KIND.tertiary}
            >
              How To Use
            </Button>
            <Button
              size="mini"
              onClick={() => {
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSe2uyBS2Kczjq9a-_W9FSg3YOY5sXzxb6M7H9A9DJjKYqK7Mw/viewform",
                  "_blank"
                )
              }}
              kind={KIND.tertiary}
              overrides={{
                StartEnhancer: {
                  style: {},
                },
              }}
            >
              Report A Bug
            </Button>
            <Button
              size="mini"
              onClick={() => {
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSeHi09mQIq3GDa8AAHAJftfbG_01Bg24bRVYfzpaNnOL_MM2A/viewform",
                  "_blank"
                )
              }}
              kind={KIND.tertiary}
              overrides={{
                StartEnhancer: {
                  style: {},
                },
              }}
            >
              Feature Request
            </Button> */}

            {/* <Button
              size="mini"
              onClick={() => {
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLScF-GvPLSvzGJIfbgMtzDCNozU0VcL4ARKomnif8Y0DXoHmVg/viewform",
                  "_blank"
                )
              }}
              kind={KIND.tertiary}
              overrides={{
                StartEnhancer: {
                  style: {},
                },
              }}
            >
              New Coloring Element Requests
            </Button> */}
            {isAuthenticated ? (
              <>
                <Button
                  size="mini"
                  onClick={() => {
                    window.location.href = "https://www.selfpublishingtitans.com/auth/me"
                  }}
                  kind={KIND.tertiary}
                  overrides={{
                    StartEnhancer: {
                      style: {},
                    },
                  }}
                >
                  Account
                </Button>

                <Button
                  size="mini"
                  onClick={handleLogout}
                  kind={KIND.tertiary}
                  overrides={{
                    StartEnhancer: {
                      style: {},
                    },
                  }}
                >
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="mini"
                  onClick={() => {
                    window.location.href = "/login"
                  }}
                  kind={KIND.tertiary}
                  overrides={{
                    StartEnhancer: {
                      style: {},
                    },
                  }}
                >
                  Login
                </Button>

                <Button
                  size="mini"
                  onClick={() => {
                    window.location.href = "https://www.selfpublishingtitans.com/auth/register"
                  }}
                  kind={KIND.tertiary}
                  overrides={{
                    StartEnhancer: {
                      style: {},
                    },
                  }}
                >
                  Create Account
                </Button>
              </>
            )}
          </Block>
        </Container>
      </ThemeProvider>
      <Modal
        onClose={() => setIsOpen(false)}
        closeable={true}
        isOpen={isOpen}
        animate
        autoFocus
        size="auto"
        role={ROLE.dialog}
        overrides={{
          Dialog: {
            style: {
              borderTopRightRadius: "8px",
              borderEndStartRadius: "8px",
              borderEndEndRadius: "8px",
              borderStartEndRadius: "8px",
              borderStartStartRadius: "8px",
            },
          },
        }}
      >
        <Block $style={{ padding: "0 1.5rem", width: "640px" }}>
          <Block
            $style={{
              padding: "2rem 1rem 1rem",
              textAlign: "center",
              fontWeight: 500,
              fontFamily: "Montserrat",
              fontSize: "2.0rem",
              marginBottom: "20px",
            }}
          >
            Download
          </Block>
          <Block className="flex flex-col items-center justify-center mb-12">
            <Block className="flex justify-center">
              <input
                type="text"
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
                style={{ fontSize: "20px" }}
                className=" w-64 h-10 rounded-md border-2 font-mono   px-4 font-medium"
              />
              <select
                onChange={(e) => setFileFormat(e.target.value)}
                style={{ fontSize: "20px" }}
                className="px-1 ml-1 mr-6 rounded-md border-2 w-20 font-mono font-medium  h-10"
              >
                <option value={"png"}>.ZIP</option>
                <option value={"pdf"}>.PDF</option>
              </select>

              <button
                style={{ fontSize: "20px" }}
                className="px-2 rounded-md border-2 w-30 font-mono font-medium hover:bg-blue-700 hover:text-white   h-10 flex items-center justify-center "
                onClick={() => handleDownload()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  // class="bi bi-download"
                  className="mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>
                Download
              </button>
            </Block>
          </Block>
        </Block>
      </Modal>
    </>
  )
}

export default Navbar
