import Theme1 from "./Themes/theme1.jpg"
import Theme2 from "./Themes/theme2.jpg"
import ChristmasLeavesTheme from './Themes/christman pattern.jpg'
import ChristmasPattern from './Themes/christmas theme.png'
import cubePattern from './Themes/cube pattern.jpg'
import flowerTheme from './Themes/flower theme.jpg'
import abstractPattern from './Themes/abstract pattern.jpg'
import leafPattern from './Themes/leaf pattern.jpg'


export const themes = [
  // abstractPattern,
  Theme1,
  // Theme2,
  ChristmasLeavesTheme,
  // ChristmasPattern,
  cubePattern,
  flowerTheme,
  // leafPattern
]