import { Block } from "baseui/block"
import { useActiveObject} from "@layerhub-io/react"
import { Button, SIZE, KIND } from "baseui/button"
import Download from "~/components/Icons/Download"
import { StatefulTooltip } from "baseui/tooltip"
import { Modal, ROLE } from "baseui/modal"
import { StatefulPopover, PLACEMENT } from "baseui/popover"
import jsPDF from 'jspdf';
import { Input } from "baseui/input"
import {useState} from 'react'

const DownloadObject = () => {
  const activeObject = useActiveObject() as any
  const [isOpen, setIsOpen] = useState(false)

  async function downloadPng() {
    const pngImage = activeObject.getSrc()
    const blob = await (await fetch(pngImage)).blob()
    await downloadElement(blob,"png")
  }
  async function downloadJpeg() {
    const pngImage = activeObject.getSrc()
    const blob = await (await fetch(pngImage)).blob()
    await downloadElement(blob,"jpeg")
  }

  async function downloadPdf(){
    const pngImage = activeObject.getSrc()
    let pdfWidth = activeObject.width
    let pdfHeight = activeObject.height
    const pdf = new jsPDF({
      orientation: 'p', 
      unit: 'px',
      format: [pdfWidth, pdfHeight],
    });

    pdf.addImage(pngImage, 'JPEG', 0, 0, pdfWidth,pdfHeight);
    const pdfBlob = pdf.output('blob');
    await downloadElement(pdfBlob,"pdf")
  }


  async function downloadElement(blobUrl: any,format:string) {
    const anchor = document.createElement("a")
    anchor.href = URL.createObjectURL(blobUrl)
    anchor.download = `object.${format}`
    document.body.appendChild(anchor)
    anchor.click()
  }

  return (
    <StatefulPopover
    placement={PLACEMENT.bottomLeft}
    content={() => (
      <Block width={"200px"} backgroundColor={"#ffffff"} className="px-2 py-2 font-primary  pb-5">
        <Block $style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Block $style={{ fontSize: "14px",fontFamily:"monospace" }}>Download</Block>
          <Block width={"52px"}></Block>
        </Block>


          <Block $style={{ display: "flex", alignItems: "center", justifyContent:"space-evenly" }} className="mt-2">
              <button className="bg-black text-white px-3 py-0.5 font-primary rounded-md mt-2" onClick={()=>downloadPng()}>
                <p className="font-mono">Png</p>
              </button>
              <button className="bg-black text-white px-3 py-0.5  font-mono rounded-md mt-2" onClick={()=>downloadPdf()}>
                pdf
              </button>
        </Block>
      </Block>
    )}
  >
    <Block>
      <StatefulTooltip placement={PLACEMENT.bottom} showArrow={true} accessibilityType={"tooltip"} content="Download">
        <Button onClick={() => setIsOpen(true)} kind={KIND.tertiary} size={SIZE.mini}>
          <Download size={24} />
        </Button>
      </StatefulTooltip>
    </Block>
    </StatefulPopover>
  )
}

export default DownloadObject
