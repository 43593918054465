function AlignBottom({ size }: { size: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24">
      <path
        d="M21 20C21 20.41 20.66 20.75 20.25 20.75H3.75C3.55109 20.75 3.36032 20.671 3.21967 20.5303C3.07902 20.3897 3 20.1989 3 20C3 19.8011 3.07902 19.6103 3.21967 19.4697C3.36032 19.329 3.55109 19.25 3.75 19.25H20.25C20.66 19.25 21 19.59 21 20ZM11 6V15C11 15.5304 10.7893 16.0391 10.4142 16.4142C10.0391 16.7893 9.53043 17 9 17H8C7.46957 17 6.96086 16.7893 6.58579 16.4142C6.21071 16.0391 6 15.5304 6 15V6C6 4.9 6.9 4 8 4H9C9.53043 4 10.0391 4.21071 10.4142 4.58579C10.7893 4.96086 11 5.46957 11 6ZM18 11V15C18 15.5304 17.7893 16.0391 17.4142 16.4142C17.0391 16.7893 16.5304 17 16 17H15C14.4696 17 13.9609 16.7893 13.5858 16.4142C13.2107 16.0391 13 15.5304 13 15V11C13 9.9 13.9 9 15 9H16C16.5304 9 17.0391 9.21071 17.4142 9.58579C17.7893 9.96086 18 10.4696 18 11ZM9.5 6C9.5 5.86739 9.44732 5.74021 9.35355 5.64645C9.25979 5.55268 9.13261 5.5 9 5.5H8C7.86739 5.5 7.74021 5.55268 7.64645 5.64645C7.55268 5.74021 7.5 5.86739 7.5 6V15C7.5 15.28 7.72 15.5 8 15.5H9C9.13261 15.5 9.25979 15.4473 9.35355 15.3536C9.44732 15.2598 9.5 15.1326 9.5 15V6ZM16.5 11C16.5 10.8674 16.4473 10.7402 16.3536 10.6464C16.2598 10.5527 16.1326 10.5 16 10.5H15C14.8674 10.5 14.7402 10.5527 14.6464 10.6464C14.5527 10.7402 14.5 10.8674 14.5 11V15C14.5 15.28 14.72 15.5 15 15.5H16C16.1326 15.5 16.2598 15.4473 16.3536 15.3536C16.4473 15.2598 16.5 15.1326 16.5 15V11Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default AlignBottom
