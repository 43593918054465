const loopworker = () => {
  onmessage = (e) => {
    const { imageData, color, showBorder, borderColor } = e.data
    let { r, g, b } = color
    if (showBorder) {
      for (let i = 0; i < imageData.data.length; i += 4) {
        const R = imageData.data[i]
        const G = imageData.data[i + 1]
        const B = imageData.data[i + 2]
        if (R != 0 && G != 0 && B != 0) {
          imageData.data[i] = r
          imageData.data[i + 1] = g
          imageData.data[i + 2] = b
        } else {
          imageData.data[i] = borderColor.r
          imageData.data[i + 1] = borderColor.g
          imageData.data[i + 2] = borderColor.b
        }
      }
    } else {
      for (let i = 0; i < imageData.data.length; i += 4) {
        let a = imageData.data[i + 3]
        if (a != 0) {
          imageData.data[i] = r
          imageData.data[i + 1] = g
          imageData.data[i + 2] = b
        }
      }
    }
    postMessage(imageData)
  }
}
let code = loopworker.toString()
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"))
const blob = new Blob([code], { type: "application/javascriptssky" })
const backgroundColorScript = URL.createObjectURL(blob)
export default backgroundColorScript