// import { Page } from "~/interfaces/common";
import { createAction } from "@reduxjs/toolkit"
interface FrameSizeState {
  currentSize: {
    id: number
    width: number
    height: number
  }
}

export const setSize = createAction<FrameSizeState>("currentFrameSize/setSize")
// export const removePage = createAction<Partial<Page>>(
//   "designEditor/removePage"
// );
