function AlignTop({ size }: { size: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24">
      <path
        d="M21 4C21 4.41 20.66 4.75 20.25 4.75H3.75C3.55109 4.75 3.36032 4.67098 3.21967 4.53033C3.07902 4.38968 3 4.19891 3 4C3 3.80109 3.07902 3.61032 3.21967 3.46967C3.36032 3.32902 3.55109 3.25 3.75 3.25H20.25C20.66 3.25 21 3.59 21 4ZM11 9V18C11 18.5304 10.7893 19.0391 10.4142 19.4142C10.0391 19.7893 9.53043 20 9 20H8C7.46957 20 6.96086 19.7893 6.58579 19.4142C6.21071 19.0391 6 18.5304 6 18V9C6 7.9 6.9 7 8 7H9C9.53043 7 10.0391 7.21071 10.4142 7.58579C10.7893 7.96086 11 8.46957 11 9ZM18 9V13C18 13.5304 17.7893 14.0391 17.4142 14.4142C17.0391 14.7893 16.5304 15 16 15H15C14.4696 15 13.9609 14.7893 13.5858 14.4142C13.2107 14.0391 13 13.5304 13 13V9C13 7.9 13.9 7 15 7H16C16.5304 7 17.0391 7.21071 17.4142 7.58579C17.7893 7.96086 18 8.46957 18 9ZM9.5 9C9.5 8.86739 9.44732 8.74021 9.35355 8.64645C9.25979 8.55268 9.13261 8.5 9 8.5H8C7.86739 8.5 7.74021 8.55268 7.64645 8.64645C7.55268 8.74021 7.5 8.86739 7.5 9V18C7.5 18.28 7.72 18.5 8 18.5H9C9.13261 18.5 9.25979 18.4473 9.35355 18.3536C9.44732 18.2598 9.5 18.1326 9.5 18V9ZM16.5 9C16.5 8.86739 16.4473 8.74021 16.3536 8.64645C16.2598 8.55268 16.1326 8.5 16 8.5H15C14.8674 8.5 14.7402 8.55268 14.6464 8.64645C14.5527 8.74021 14.5 8.86739 14.5 9V13C14.5 13.28 14.72 13.5 15 13.5H16C16.1326 13.5 16.2598 13.4473 16.3536 13.3536C16.4473 13.2598 16.5 13.1326 16.5 13V9Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default AlignTop
