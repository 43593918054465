function AlignMiddle({ size }: { size: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24">
      <path
        d="M11 11.25H13V7C13 5.9 13.9 5 15 5H16C16.5304 5 17.0391 5.21071 17.4142 5.58579C17.7893 5.96086 18 6.46957 18 7V11.25H20.25C20.4489 11.25 20.6397 11.329 20.7803 11.4697C20.921 11.6103 21 11.8011 21 12C21 12.1989 20.921 12.3897 20.7803 12.5303C20.6397 12.671 20.4489 12.75 20.25 12.75H18V17C18 17.5304 17.7893 18.0391 17.4142 18.4142C17.0391 18.7893 16.5304 19 16 19H15C14.4696 19 13.9609 18.7893 13.5858 18.4142C13.2107 18.0391 13 17.5304 13 17V12.75H11V14C11 14.5304 10.7893 15.0391 10.4142 15.4142C10.0391 15.7893 9.53043 16 9 16H8C7.46957 16 6.96086 15.7893 6.58579 15.4142C6.21071 15.0391 6 14.5304 6 14V12.75H3.75C3.55109 12.75 3.36032 12.671 3.21967 12.5303C3.07902 12.3897 3 12.1989 3 12C3 11.8011 3.07902 11.6103 3.21967 11.4697C3.36032 11.329 3.55109 11.25 3.75 11.25H6V10C6 8.9 6.9 8 8 8H9C9.53043 8 10.0391 8.21071 10.4142 8.58579C10.7893 8.96086 11 9.46957 11 10V11.25ZM16.5 7C16.5 6.86739 16.4473 6.74021 16.3536 6.64645C16.2598 6.55268 16.1326 6.5 16 6.5H15C14.8674 6.5 14.7402 6.55268 14.6464 6.64645C14.5527 6.74021 14.5 6.86739 14.5 7V17C14.5 17.28 14.72 17.5 15 17.5H16C16.1326 17.5 16.2598 17.4473 16.3536 17.3536C16.4473 17.2598 16.5 17.1326 16.5 17V7ZM9.5 10C9.5 9.86739 9.44732 9.74021 9.35355 9.64645C9.25979 9.55268 9.13261 9.5 9 9.5H8C7.86739 9.5 7.74021 9.55268 7.64645 9.64645C7.55268 9.74021 7.5 9.86739 7.5 10V14C7.5 14.28 7.72 14.5 8 14.5H9C9.13261 14.5 9.25979 14.4473 9.35355 14.3536C9.44732 14.2598 9.5 14.1326 9.5 14V10Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default AlignMiddle
