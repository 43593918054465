import TextProperties from "./TextProperties"
import PageSizes from "./PageSizes"
import SearchComponent from "./Search"
import Categories from "./Categories"
import Uploads from "./Uploads"
import Layers from "./Layers"
import themesComponent from "./Themes"
import CanvasBackground from "./CanvasBackground"
export default {
  StaticText: TextProperties,
  PageSizes: PageSizes,
  Search: SearchComponent,
  Categories: Categories,
  Uploads: Uploads,
  Layers: Layers,
  ElementThemes: themesComponent,
  CanvasBackground: CanvasBackground
}
