function Bold({ size }: { size: number }) {
  return (
    <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2155 10.9973C16.7072 10.2029 16.9776 9.29148 16.9987 8.35747C17.0197 7.42346 16.7906 6.50081 16.3351 5.68511C15.8797 4.8694 15.2144 4.19032 14.4082 3.71821C13.602 3.2461 12.6842 2.99814 11.75 3.00001H5V21H14C15.1951 21.0013 16.3547 20.5944 17.2871 19.8468C18.2194 19.0992 18.8685 18.0556 19.1269 16.8888C19.3854 15.722 19.2376 14.502 18.7082 13.4306C18.1787 12.3592 17.2993 11.5007 16.2155 10.9973V10.9973ZM8 6.00001H11.75C12.3467 6.00001 12.919 6.23706 13.341 6.65902C13.7629 7.08097 14 7.65327 14 8.25001C14 8.84674 13.7629 9.41904 13.341 9.841C12.919 10.263 12.3467 10.5 11.75 10.5H8V6.00001ZM14 18H8V13.5H14C14.5967 13.5 15.169 13.7371 15.591 14.159C16.0129 14.581 16.25 15.1533 16.25 15.75C16.25 16.3467 16.0129 16.919 15.591 17.341C15.169 17.7629 14.5967 18 14 18V18Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Bold
