import axios from "axios"
import CryptoJS from "crypto-js"
import { LocalScenesClassDB, localScenesClassDB } from "~/indexDB/db"
// import * as AWS from "aws-sdk"
// import { S3Client, AbortMultipartUploadCommand, GetObjectCommand } from "@aws-sdk/client-s3"

const api = axios.create({
  baseURL: import.meta.env.VITE_SERVER_URL,
})

// const config = {
//   //Get the endpoint from the DO website for your space
//   endpoint: import.meta.env.VITE_DOS_ENDPOINT,
//   region: "us-east-1",
//   useAccelerateEndpoint: false,
//   //Create a credential using DO Spaces API key (https://cloud.digitalocean.com/account/api/tokens)
//   credentials: new AWS.Credentials(import.meta.env.VITE_DOS_KEY, import.meta.env.VITE_DOS_SECRET),
// }

// AWS.config.update(config)
// const client = new S3Client(config);

// const s3 = new AWS.S3()

export interface DigitalAssetsImage {
  _id: string
  filename: string
  fpath: string
  height: Number
  width: Number
  id: string
}
//for coloring elements
export const getDigitalAssetsElements = (): Promise<DigitalAssetsImage[]> => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/digital-assets/all`)
      .then((response) => {
        const data = response.data.data
        const bytes = CryptoJS.AES.decrypt(data, import.meta.env.VITE_DIGITAL_ASSETS_KEY)
        const jsonData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        resolve(jsonData)
      })
      .catch((err) => reject(err))
  })
}
//for alphabets
export const getAllDigitalAssetsImages = (): Promise<DigitalAssetsImage[]> => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/digital-assets/all-alphabets`)
      .then((response) => {
        const data = response.data.data
        const bytes = CryptoJS.AES.decrypt(data, import.meta.env.VITE_DIGITAL_ASSETS_KEY)
        const jsonData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        resolve(jsonData)
      })
      .catch((err) => reject(err))
  })
}

// get image url to base64
export const getBase64ImageFromURL = (
  image: string
): Promise<{
  url: string
}> => {
  return new Promise(async (resolve, reject) => {
    // https://booksbytitans-bucket.sgp1.cdn.digitaloceanspaces.com/coloring-book-maker-assets%2FAnimal%20Words%2Fwhale%20text.png
    const key = `coloring-book-maker-assets${image}`

    let downloadParameters = {
      Bucket: "booksbytitans-bucket",
      Key: key,
      Expires: 5,
    }

    try {
      // POST /api/download/temp-image-url
      const { data } = await api.post("/api/download/temp-image-url", {
        downloadParameters: downloadParameters,
      })
      const url = data.url
      // const url = s3.getSignedUrl("getObject", downloadParameters)
      resolve({ url: url })
      // toDataUrl(url, function (myBase64: string) {
      //   resolve({
      //     base64: myBase64,
      //     url: url,
      //   })
      // })
    } catch (e) {
      reject(e)
    }
  })
}

// sync data by user id
export const syncDataByUserId = async ({ token, data }: { token: string; data: any }): Promise<{ data: any }> => {
  // console.log("syncDataByUserId", data)
  const prevScenes = await localScenesClassDB.scenes.get(1)

  return new Promise((resolve, reject) => {
    if (prevScenes) {
      localScenesClassDB.scenes
        .update(1, {
          scenes: data,
        })
        .then((res) => {
          resolve({ data: data })
        })
        .catch((err) => {
          reject(err)
        })
    } else {
      localScenesClassDB.scenes
        .add({
          id: 1,
          scenes: data,
        })
        .then((res) => {
          resolve({ data: data })
        })
        .catch((err) => {
          reject(err)
        })
    }
  })
  //   api
  //     .post(
  //       `/api/coloring-book/sync-data/`,

  //       {
  //         data: data,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       console.log("response", response)
  //       resolve(response.data)
  //     })
  //     .catch((err) => reject(err))
  // })
}

// get data by user id
export const getSyncDataByUserId = ({ token }: { token: string }): Promise<{ data: any }> => {
  return new Promise((resolve, reject) => {
    localScenesClassDB.scenes
      .get(1)
      .then((res) => {
        // console.log("res", res)
        if (res) {
          resolve(res.scenes as any)
        } else {
          resolve({ scenes: [] } as any)
        }
        //resolve(res)
      })
      .catch((err) => {
        console.log("err", err)
        reject(err)
      })
    // api
    //   .get(`/api/coloring-book/get-data/`, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   .then((response) => {
    //     resolve(response.data)
    //   })
    //   .catch((err) => reject(err))
  })
}

// delete all data by user id
export const deleteAllDataByUserId = ({ token }: { token: string }): Promise<{ data: any }> => {
  return new Promise((resolve, reject) => {
    localScenesClassDB.scenes
      .delete(1)
      .then((res) => {
        resolve({
          data: res,
        })
      })
      .catch((err) => {
        reject(err)
      })
    // api
    //   .delete(`/api/coloring-book/delete-all-data/`, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   .then((response) => {
    //     resolve(response.data)
    //   })
    //   .catch((err) => reject(err))
  })
}
