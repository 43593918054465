export function isHttpUrl(str: string) {
    const pattern = /^(http|https):\/\/.+/i
    return pattern.test(str)
  }

export function isArrayOfObjects(arr: any) {
    if (Array.isArray(arr) && arr.length > 0) {
      return typeof arr[0] === "object" && !Array.isArray(arr[0])
    }
    return false
  }
  