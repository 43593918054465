import { nanoid } from "nanoid"
import { IFrame, IScene } from "@layerhub-io/types"

export const defaultTemplate: IScene = {
  id: nanoid(),
  frame: {
    width: 1200,
    height: 1200,
  },
  layers: [
    {
      id: "background",
      name: "Initial Frame",
      left: 0,
      top: 0,
      width: 1200,
      height: 1200,
      type: "Background",
      fill: "#ffffff",
      metadata: {},
    },
  ],
  metadata: {},
}

export const getDefaultTemplate = ({ width, height }: IFrame) => {
  return {
    id: nanoid(),
    frame: {
      width,
      height,
    },
    layers: [
      {
        id: "background",
        name: "Initial Frame",
        left: 0,
        top: 0,
        width,
        height,
        type: "Background",
        fill: "transparent",
        metadata: {},
      },
    ],
    metadata: {},
  }
}
export const getCustomTemplate = ( width:any, height:any,layer:any) => {
  return {
    id: nanoid(),
    frame: {
      width,
      height,
    },
    layers: [
      {
        id: "background",
        name: "Initial Frame",
        left: 0,
        top: 0,
        width,
        height,
        type: "Background",
        fill: "transparent",
        metadata: {},
      },
      {
        ...layer
      }
    ],
    metadata: {},
  }
}

export const getDefaultLayer = async (src:string,width:number,height:number,leftOffset:number,topOffset:number) =>  {
  return {
      "id": nanoid(),
      "name": "StaticImage",
      "angle": 0,
      "stroke": null,
      "strokeWidth": 0,
      "left": leftOffset,
      "top": topOffset,
      "width": width,
      "height": height,
      "opacity": 1,
      "originX": "left",
      "originY": "top",
      "scaleX": 0.50,
      "scaleY": 0.50,
      "type": "StaticImage",
      "flipX": false,
      "flipY": false,
      "skewX": 0,
      "skewY": 0,
      "visible": true,
      "shadow": null,
      "src": src,
      "cropX": 0,
      "cropY": 0,
      "metadata": {"ogSrc":src}
  }
}

export const TEXT_EFFECTS = [
  {
    id: 1,
    name: "None",
    preview: "https://i.ibb.co/Wyx2Ftf/none.webp",
  },
  {
    id: 2,
    name: "Shadow",
    preview: "https://i.ibb.co/HBQc95J/shadow.webp",
  },
  {
    id: 3,
    name: "Lift",
    preview: "https://i.ibb.co/M7zpk5f/lift.webp",
  },
  {
    id: 4,
    name: "Hollow",
    preview: "https://i.ibb.co/vhjCd4w/hollow.webp",
  },
  {
    id: 5,
    name: "Splice",
    preview: "https://i.ibb.co/B2JPTfq/splice.webp",
  },
  {
    id: 6,
    name: "Neon",
    preview: "https://i.ibb.co/fHdD2mx/neon.webp",
  },
]
