import React, { useState } from "react"
const Collapsible = ({ title, children }: any) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className=" border-gray-300 px-4 py-1 mb-4 transition-all">
      <div className="flex justify-between items-center cursor-pointer transition-all" onClick={() => setIsOpen(!isOpen)}>
        <h2 className="mx-2 text-lg font-semibold">{title}</h2>
        <svg
          style={{ transform: `rotate(${isOpen ? "0deg" : "-90deg"})` }}
          className="w-6 h-6 transition-all"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </div>
      {isOpen && (
        <div
          className={`mt-4 overflow-hidden transition-all duration-300 ease-in-out ${
            isOpen ? "max-h-screen" : "max-h-0"
          }`}
        >
          {children}
        </div>
      )}
    </div>
  )
}

export default Collapsible
