import { currentFrameSizeReducer } from "./slices/current-frame-size/reducer"
import { combineReducers } from "@reduxjs/toolkit"
import { designEditorReducer } from "./slices/design-editor/reducer"
import { fontsReducer } from "./slices/fonts/reducer"
import { uploadsReducer } from "./slices/uploads/reducer"
import { resourcesReducer } from "./slices/resources/reducer"
import { designsReducer } from "./slices/designs/reducer"
import { componentsReducer } from "./slices/components/reducer"
import { authReducer, isOwnerReducer } from "./slices/auth/reducer"
import { snackBarReducer } from "./slices/snackbar/reducer"
import { loadingReducer } from "./slices/loading-status/reducer"
import {assestsReducer} from './slices/assets/reducer'

const rootReducer = combineReducers({
  designEditor: designEditorReducer,
  fonts: fontsReducer,
  uploads: uploadsReducer,
  resources: resourcesReducer,
  designs: designsReducer,
  components: componentsReducer,
  currentFrameSizeReducer: currentFrameSizeReducer,
  auth: authReducer,
  isOwner: isOwnerReducer,
  snackBar: snackBarReducer,
  loading: loadingReducer,
  assets: assestsReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
