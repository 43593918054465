import { createReducer } from "@reduxjs/toolkit"
import { ILoadingStatusState, setLoadingStatus } from "./actions"

const initialState: ILoadingStatusState = {
  isLoading: false,
  title: "Loading...",
}

export const loadingReducer = createReducer(initialState, (builder) => {
  builder.addCase(setLoadingStatus, (state, { payload }) => {
    state.isLoading = payload.isLoading
    state.title = payload.title
  })
})

// export const isOwnerReducer = createReducer(isOwnerInitialState, (builder) => {
//   builder.addCase(setOwner, async (state) => {
//     state.isOwner = await isOwner()
//   })
//   // builder.addCase(setOwner, (state, {payload}) => {
//   //   state.isOwner =
//   // })
// })
