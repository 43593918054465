const loopworker = () => {
  onmessage = (e) => {
    const { imageData, imageData2, showBorder, borderColor } = e.data
    let { r, g, b } = borderColor
    if (showBorder) {
      for (let i = 0; i < imageData.data.length; i += 4) {
        const r = imageData.data[i]
        const g = imageData.data[i + 1]
        const b = imageData.data[i + 2]
        if (r == 255 && g == 255 && b == 255) {
          imageData.data[i] = imageData2.data[i]
          imageData.data[i + 1] = imageData2.data[i + 1]
          imageData.data[i + 2] = imageData2.data[i + 2]
          imageData.data[i + 3] = imageData2.data[i + 3]
        } else {
          imageData.data[i] = borderColor.r
          imageData.data[i + 1] = borderColor.g
          imageData.data[i + 2] = borderColor.b
        }
      }
    } else {
      for (let i = 0; i < imageData.data.length; i += 4) {
        let a = imageData.data[i + 3]
        if (a != 0) {
          imageData.data[i] = imageData2.data[i]
          imageData.data[i + 1] = imageData2.data[i + 1]
          imageData.data[i + 2] = imageData2.data[i + 2]
          imageData.data[i + 3] = imageData2.data[i + 3]
        }
      }
    }
    postMessage(imageData)
  }
}

let code = loopworker.toString()
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"))
const blob = new Blob([code], { type: "application/javascriptssky" })
const workerScript = URL.createObjectURL(blob)
export default workerScript
