function ClearAll({ size }: { size: number }) {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 409.32"><path d="M5.4 281.99h181.22c2.22 27.53 10.52 53.34 23.57 76.07H5.4c-2.8 0-5.4-2.42-5.4-5.4V287.4c0-2.99 2.42-5.41 5.4-5.41zm0-64.93h187.52c8.18-28.84 23.25-54.82 43.32-76.07H5.4c-2.8 0-5.4 2.52-5.4 5.4v65.26c0 2.89 2.42 5.41 5.4 5.41zM5.4 0h395.95c2.99 0 5.41 2.6 5.41 5.4v65.26c0 2.8-2.61 5.41-5.41 5.41H5.4c-2.8 0-5.4-2.42-5.4-5.41V5.4C0 2.42 2.42 0 5.4 0z"/><path fill="#F44336" d="M369.69 124.69c78.6 0 142.31 63.71 142.31 142.32 0 78.6-63.71 142.31-142.31 142.31s-142.32-63.71-142.32-142.31c0-78.61 63.72-142.32 142.32-142.32zm67.7 116.77c7.66-.05 13.14-.77 13 8.73l-.37 30.78c.05 9.92-3.1 12.56-12.4 12.45H301.74c-9.3.11-12.46-2.53-12.4-12.45l-.37-30.78c-.14-9.5 5.34-8.78 12.99-8.73h135.43z"/></svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 512 409.32"
      // viewBox="0 0 24 24"
    >
      <path d="M5.4 281.99h181.22c2.22 27.53 10.52 53.34 23.57 76.07H5.4c-2.8 0-5.4-2.42-5.4-5.4V287.4c0-2.99 2.42-5.41 5.4-5.41zm0-64.93h187.52c8.18-28.84 23.25-54.82 43.32-76.07H5.4c-2.8 0-5.4 2.52-5.4 5.4v65.26c0 2.89 2.42 5.41 5.4 5.41zM5.4 0h395.95c2.99 0 5.41 2.6 5.41 5.4v65.26c0 2.8-2.61 5.41-5.41 5.41H5.4c-2.8 0-5.4-2.42-5.4-5.41V5.4C0 2.42 2.42 0 5.4 0z" />
      <path
        fill="#F44336"
        d="M369.69 124.69c78.6 0 142.31 63.71 142.31 142.32 0 78.6-63.71 142.31-142.31 142.31s-142.32-63.71-142.32-142.31c0-78.61 63.72-142.32 142.32-142.32zm67.7 116.77c7.66-.05 13.14-.77 13 8.73l-.37 30.78c.05 9.92-3.1 12.56-12.4 12.45H301.74c-9.3.11-12.46-2.53-12.4-12.45l-.37-30.78c-.14-9.5 5.34-8.78 12.99-8.73h135.43z"
      />
    </svg>
  )
}

export default ClearAll
