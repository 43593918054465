import { createReducer } from "@reduxjs/toolkit"
import { ISnackBarState, openSnackBar, closeSnackBar } from "./actions"

const initialState: ISnackBarState = {
  isOpen: false,
  title: "Error Occurred",
  message: "Something went wrong",
  timeout: 5000,
  KIND: "error",
}

export const snackBarReducer = createReducer(initialState, (builder) => {
  builder.addCase(openSnackBar, (state, { payload }) => {
    state.isOpen = true
    state.title = payload.title
    state.message = payload.message
    state.KIND = payload.KIND
    state.timeout = payload.timeout
  })

  builder.addCase(closeSnackBar, (state) => {
    state.isOpen = false
    state.title = ""
    state.message = ""
    state.KIND = undefined
  })
})

// export const isOwnerReducer = createReducer(isOwnerInitialState, (builder) => {
//   builder.addCase(setOwner, async (state) => {
//     state.isOwner = await isOwner()
//   })
//   // builder.addCase(setOwner, (state, {payload}) => {
//   //   state.isOwner =
//   // })
// })
