const loopworker = () => {
    onmessage = (e) => {
      const { imageData, imageData2, color, showBorder } = e.data
      let {r,g,b} = color
      if (showBorder) {
        for (let i = 0; i < imageData.data.length; i += 4) {
          const R = imageData.data[i]
          const G = imageData.data[i + 1]
          const B = imageData.data[i + 2]
          if (R == 0 && G == 0 && B == 0) {
            imageData.data[i] = r
            imageData.data[i + 1] = g
            imageData.data[i + 2] = b
          }else{
            imageData.data[i] = imageData2.data[i]
            imageData.data[i + 1] = imageData2.data[i + 1]
            imageData.data[i + 2] = imageData2.data[i + 2]
            imageData.data[i + 3] = imageData2.data[i + 3]
          }
        }
      } 
      postMessage(imageData)
    }
  }
  
  let code = loopworker.toString()
  code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"))
  const blob = new Blob([code], { type: "application/javascriptssky" })
  const borderColorScript = URL.createObjectURL(blob)
  export default borderColorScript
  