import { createSlice,createReducer } from "@reduxjs/toolkit";
import {setLettersData,setElementsData} from './actions'

export interface AssetsState {
    Letters: any[],
    Elements: any[]
}

const initialState: AssetsState = {
  Letters: [],
  Elements: []
};

export const assestsReducer = createReducer(initialState, (builder) => {
    builder.addCase(setLettersData, (state, { payload }) => {
      //@ts-ignore
        state.Letters = payload
    })

    builder.addCase(setElementsData, (state, { payload }) => {
      //@ts-ignore
        state.Elements = payload
    })
  })
  
