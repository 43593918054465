export const BASE_ITEMS = [
  // {
  //   id: "search",
  //   name: "Search",
  // },
  {
    id: "categories",
    name: "Categories",
  },

  {
    id: "uploads",
    name: "Uploads",
  },{
    id:"CanvasBackground",
    name:"Canvas Background"
  },
  {
    id: "ElementThemes",
    name: "Element Themes",
  },
  {
    id: "pageSizes",
    name: "PageSizes",
  },
]

export enum PanelType {
  SEARCH = "Search",
  CATEGORIES = "Categories",
  BACKGROUND = "Background",
}