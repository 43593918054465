import React from "react"
import { Block } from "baseui/block"
import AngleDoubleLeft from "~/components/Icons/AngleDoubleLeft"
import Scrollable from "~/components/Scrollable"
import { Button, SIZE } from "baseui/button"
import DropZone from "~/components/Dropzone"
import { useEditor } from "@layerhub-io/react"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import { nanoid } from "nanoid"
import { captureFrame, loadVideoResource } from "~/utils/video"
import { ILayer } from "@layerhub-io/types"
import { useState } from "react"
import { toBase64 } from "~/utils/data"
import { uploadedImagesClassDB } from "~/indexDB/db"
import exampleObjectImage from "../../../../../../public/images/images/exampleObject.png"
import { LazyLoadImage, trackWindowScroll } from "react-lazy-load-image-component"

export default function () {
  const inputFileRef = React.useRef<HTMLInputElement>(null)
  const [uploads, setUploads] = React.useState<any[]>([])
  const editor = useEditor()
  const setIsSidebarOpen = useSetIsSidebarOpen()

  React.useEffect(() => {
    ;(async () => {
      uploadedImagesClassDB.images.get(1).then((data) => {
        if (data) {
          setUploads(data.images)
        }
      })
    })()
  }, [])
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleDropFiles = async (files: FileList) => {
    const newUploads = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const base64 = (await toBase64(file)) as string
      const type = "StaticImage"
      const upload = {
        id: nanoid(),
        src: base64,
        preview: base64,
        type: type,
        sourceType: "Local",
        metadata: {
          ogSrc: base64,
        },
      }
      newUploads.push(upload)
    }

    setUploads([...uploads, ...newUploads])

    const prevImages = await uploadedImagesClassDB.images.get(1)

    if (prevImages !== undefined) {
      await uploadedImagesClassDB.images.update(1, {
        images: [...uploads, ...newUploads],
        createdAt: new Date(),
      })
    } else {
      await uploadedImagesClassDB.images.add({
        id: 1,
        images: [...uploads, ...newUploads],
        createdAt: new Date(),
      })
    }
  }

  const handleInputFileRefClick = () => {
    inputFileRef.current?.click()
  }

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleDropFiles(e.target.files!)
  }

  const addImageToCanvas = (props: Partial<ILayer>) => {
    editor.objects.add(props).then(() => {
      editor.objects.scale("fit", props.id!)
    })
  }

  const deleteAll = async () => {
    setUploads([])
    await uploadedImagesClassDB.images.clear()
  }
  const imageSource = isHovered ? exampleObjectImage : ""
  return (
    <DropZone handleDropFiles={handleDropFiles}>
      <Block $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Block
          $style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
            justifyContent: "space-between",
            padding: "1.5rem",
          }}
        >
          <Block className="font-primary">Upload Elements</Block>

          <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
            <AngleDoubleLeft size={18} />
          </Block>
        </Block>
        <Scrollable>
          <Block padding={"0 1.5rem"}>
            <Button
              onClick={handleInputFileRefClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="flex justify-center cursor-pointer w-full items-center rounded-md border-2 px-5 py-0"
            >
              <div
                className={`${
                  isHovered ? "w-6 mr-1" : ""
                } flex font-primary  transition-all justify-center items-center`}
              >
                {isHovered && (
                  <LazyLoadImage
                    className={` transition-all `}
                    style={{ height: "20px", marginRight: "0.5rem" }}
                    alt={"upload image"}
                    src={imageSource}
                    onContextMenu={(e) => {
                      e.preventDefault()
                    }}
                  />
                )}
              </div>
              Upload Elements
            </Button>
            <Block className="rounded-md transition-all mt-2 mb-2 flex justify-center items-center font-mono  my-1">
              <div className=" bg-blue-200 rounded-md px-2">
                <p className=" text-xs   ">Note: upload Icons/Elements Only</p>
              </div>
            </Block>
            <Button
              onClick={deleteAll}
              size={SIZE.compact}
              overrides={{
                Root: {
                  style: {
                    marginTop: "0.5rem",
                    width: "100%",
                    backgroundColor: "#f50057",
                  },
                },
              }}
            >
              Delete All
            </Button>
            <input
              onChange={handleFileInput}
              type="file"
              id="file"
              multiple
              ref={inputFileRef}
              style={{ display: "none" }}
            />

            <div
              style={{
                marginTop: "1rem",
                display: "grid",
                gap: "0.5rem",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              {uploads.map((upload) => (
                <div
                  key={upload.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => addImageToCanvas(upload)}
                >
                  <div>
                    <img width="100%" src={upload.preview ? upload.preview : upload.url} alt="preview" />
                  </div>
                </div>
              ))}
            </div>
          </Block>
        </Scrollable>
      </Block>
    </DropZone>
  )
}
