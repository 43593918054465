import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { isLoadingSelector, loadingTitleSelector } from "~/store/slices/loading-status/selectors"

function CircularLoading() {
  const isLoading = useSelector(isLoadingSelector)

  const title = useSelector(loadingTitleSelector)

  return (
    <div
      className={`fixed text-center backdrop-blur-sm w-[100vw] h-[100vh] bg-white/30 top-[0] left-[0] z-[10] ${
        isLoading ? null : "hidden"
      }`}
    >
      <div>
        <div className="flex flex-col w-[100vw] h-[100vh] items-center justify-center">
          <div
            className="inline-block h-24 w-24 animate-spin rounded-full border-8 border-solid border-secCol1-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
          <h1 className="text-2xl">{title}</h1>
        </div>
      </div>
    </div>
  )
}

export default CircularLoading
