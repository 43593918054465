import React, { Children, useEffect, useState } from "react"
import { useEditor } from "@layerhub-io/react"
import { Block } from "baseui/block"
import Scrollable from "~/components/Scrollable"
import AngleDoubleLeft from "~/components/Icons/AngleDoubleLeft"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import { getAllDigitalAssetsImages } from "~/services/digital-assets"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { SIZE, Spinner } from "baseui/spinner"
import "react-lazy-load-image-component/src/effects/blur.css"
import { v4 as uuidv4 } from "uuid"
import { Input } from "baseui/input"
import Search from "~/components/Icons/Search"
import Fuse from "fuse.js"
import { IScene } from "@layerhub-io/types"
import { useDispatch, useSelector } from "react-redux"
import { isOwnerSelector } from "~/store/slices/auth/selectors"
const spaceBaseUrl = "https://booksbytitans-bucket.sgp1.digitaloceanspaces.com"
import { setLettersData } from "~/store/slices/assets/actions"
import { selectLettersData } from "~/store/slices/assets/selectors"
import { scenesClassDB, uploadedImagesClassDB } from "~/indexDB/db"
import { setLoadingStatus } from "~/store/slices/loading-status/actions"
import { getDefaultTemplate, getDefaultLayer, getCustomTemplate } from "~/constants/design-editor"
import { nanoid } from "nanoid"
import { DesignEditorContext } from "~/contexts/DesignEditor"
import { getSyncDataByUserId, syncDataByUserId } from "~/services/digital-assets"
import { openSnackBar } from "~/store/slices/snackbar/actions"
import { isArrayOfObjects } from "~/utils/types"
import useDesignEditorPages from "~/hooks/useDesignEditorScenes"

const convertFilesToFolderStructure = (files: any) => {
  const folderSystem: any = []

  files.forEach((file: any) => {
    const pathComponents = file.fpath.split("/")
    let currentLevel = folderSystem

    pathComponents.forEach((component: any, index: number) => {
      const isLast = index === pathComponents.length - 1

      const existingItem = currentLevel.find((item: any) => item.name === component)

      if (existingItem) {
        if (isLast) {
          existingItem.files.push({
            type: "file",
            file: file,
          })
        } else {
          currentLevel = existingItem.children
        }
      } else {
        const newItem = {
          type: isLast ? "file" : "dir",
          name: component,
          children: [],
          files: isLast ? [{ type: "file", file: file }] : [],
        }

        currentLevel.push(newItem)

        if (!isLast) {
          currentLevel = newItem.children
        }
      }
    })
  })

  return folderSystem
}

function Folder({ name, file, count = 0, folderNames = [] }: any) {
  const scenes = useDesignEditorPages()
  const isOwner = useSelector(isOwnerSelector).isOwner
  count++
  const dispatch = useDispatch()
  const [expand, setExpand] = useState(false)
  const editor = useEditor()
  const { setScenes, setCurrentScene, currentScene, setCurrentDesign, currentDesign } =
    React.useContext(DesignEditorContext)

  let data = file.files
  //@ts-ignore
  data.sort((a, b) => {
    const filenameA = a.file.filename.toLowerCase()
    const filenameB = b.file.filename.toLowerCase()
    if (filenameA < filenameB) return -1
    if (filenameA > filenameB) return 1
    return 0
  })

  const setPrevData = async () => {
    dispatch(
      setLoadingStatus({
        isLoading: true,
        title: "Loading scenes...",
      })
    )

    setTimeout(async () => {
      dispatch(
        setLoadingStatus({
          isLoading: false,
          title: "",
        })
      )
    }, 1000 * 60)

    let localImages: any = null
    let prevData: any = null
    let localData: any = null
    let scenes: any = false

    try {
      const token = sessionStorage.getItem("token")
      localImages = await uploadedImagesClassDB.images.get(1)

      if (token) {
        localData = await getSyncDataByUserId({ token })
      }
    } catch (error: any) {
      if (error && error.message && error.message === "Network Error") {
        dispatch(
          openSnackBar({
            title: "Network Error",
            message: "Unable to connect to server",
            KIND: "error",
          })
        )
      } else if (error && error.response && error.response.status === 401) {
        dispatch(
          openSnackBar({
            title: "Unauthorized",
            message: "Please login again",
            KIND: "error",
          })
        )
      } else if (error && error.response && error.response.status === 500) {
        dispatch(
          openSnackBar({
            title: "Server Error",
            message: "Unable to connect to server",
            KIND: "error",
          })
        )
      } else if (error && error.message) {
        dispatch(
          openSnackBar({
            title: "Error loading scenes",
            message: error.message,
            KIND: "error",
          })
        )
      } else {
        dispatch(
          openSnackBar({
            title: "Error loading scenes",
            message: "Unable to download data from server",
            KIND: "error",
          })
        )
      }
    }

    if (localData && localData.length > 0) {
      scenes = localData
    } else {
      // legacy code for local storage
      prevData = await scenesClassDB.scenes.get(1)

      let unpackedScenes = null
      try {
        unpackedScenes = jsonpack.unpack(prevData.scenes)

        if (unpackedScenes) {
          // console.warn("unpacked scenes")
          prevData.scenes = unpackedScenes

          scenes = prevData.scenes
        }
      } catch (error) {
        try {
          scenes = prevData.scenes
        } catch (error) {
          console.error("Unable to set localdata")
          console.error(error)
        }
        console.error(error)
      }
    }

    if (scenes) {
      try {
        for (let index = 0; index < scenes.length; index++) {
          const element = scenes[index]
          let spliceIndex: number[] = []
          for (let i: number = 0; i < element.layers.length; i++) {
            const l = element.layers[i]

            if (l.type === "StaticImage" && !l.hasOwnProperty("src")) {
              let localImage = await localImages?.images.find((i: any) => i.id === l.id)
              if (localImage) {
                l["src"] = localImage.src
                l["preview"] = localImage.src
              } else {
                spliceIndex.push(i)
              }
            }

            for (let index in spliceIndex) {
              element.layers.splice(spliceIndex[index], 1)
            }
          }

          try {
            const updatedPreview = (await editor.renderer.render(element)) as string

            scenes[index] = { ...element, preview: updatedPreview }

            if (index === scenes.length - 1) {
              setCurrentScene({ ...element, preview: updatedPreview })
            }
          } catch (error) {
            console.error("error rendering")
            console.error(error)
          }
        }
        setScenes(scenes)
      } catch (error) {
        console.error(error)
        dispatch(
          openSnackBar({
            title: "Error loading scenes",
            message: "Unable to load scenes",
            KIND: "error",
          })
        )
      }

      // setCurrentScene(prevData.currentScenes)
    }
    dispatch(
      setLoadingStatus({
        isLoading: false,
        title: "",
      })
    )
  }

  const addObject = React.useCallback(
    (url: string) => {
      if (!isOwner) {
        alert("To add images to the canvas, one needs to purchase Digital Titans Designer first.")
        return
      }
      const id = uuidv4()
      const finalUrl = spaceBaseUrl + "/alphabet-public" + url
      const options = {
        id: id,
        type: "StaticImage",
        src: finalUrl,
        metadata: { ogSrc: finalUrl },
      }
      editor.objects.add(options).then(() => {
        editor.objects.scale("fit", id)
      })
    },
    [editor, isOwner]
  )

  const syncData = async ({ updatedScenes }: { updatedScenes: IScene[] }) => {
    const token = sessionStorage.getItem("token")
    if (token) {
      const updatedPages = updatedScenes.map((p) => {
        // const { ["preview"]: _, ...rest } = p
        let rest = p
        return rest
      })

      syncDataByUserId({
        token,
        data: updatedPages,
      })
        .then((res) => {})
        .catch((err) => {
          console.error(err)
          dispatch(
            openSnackBar({
              title: "Error syncing scenes",
              message: "Unable to sync scenes",
              KIND: "error",
            })
          )
        })
    }
  }

  //adding bulk scenes to the editor
  const addScenes = async () => {
    dispatch(
      setLoadingStatus({
        isLoading: true,
        title: "Adding Images...",
      })
    )

    let pages: any = []
    let letterImages = data
    const updatedTemplate = editor.scene.exportToJSON()
    const updatedPreview = await editor.renderer.render(updatedTemplate)
    const updatedPages = scenes.map((p) => {
      if (p.id === updatedTemplate.id) {
        return { ...updatedTemplate, preview: updatedPreview }
      }
      return p
    })

    for (let i = 0; i < letterImages.length; i++) {
      let file = letterImages[i].file
      let src = `https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/alphabet-public${file.fpath}/${file.filename}`
      let width = currentDesign.frame.width
      let height = currentDesign.frame.height
      let leftOffset = (currentDesign.frame.width - file.width / 2) / 2
      let topOffset = (currentDesign.frame.height - file.height / 2) / 2
      let defaultLayer = await getDefaultLayer(src, file.width, file.height, leftOffset, topOffset)
      let defaultTemplate = getCustomTemplate(width, height, defaultLayer)
      const newPreview = await editor.renderer.render(defaultTemplate)
      const newPage = {
        ...defaultTemplate,
        id: nanoid(),
        preview: newPreview,
        metadata: {
          animated: false,
        },
        name: currentDesign.name,
      }
      pages.push(newPage)
    }

    const newPages = [...updatedPages, ...pages] as any[]
    setScenes(newPages)
    setCurrentScene(pages[pages.length - 1])
    syncData({ updatedScenes: newPages })
    const TimelineItemsContainer2 = document.getElementById("TimelineItemsContainer")
    TimelineItemsContainer2?.scrollTo({
      top: 0,
      left: TimelineItemsContainer2.scrollWidth + 100,
      behavior: "smooth",
    })
    dispatch(
      setLoadingStatus({
        isLoading: false,
        title: "",
      })
    )
  }
  const PreviewLetters = () => {
    let allLetters = file.children[0].children[3].files
    allLetters.sort((a: any, b: any) => {
      const filenameA = a.file.filename.toLowerCase()
      const filenameB = b.file.filename.toLowerCase()
      if (filenameA < filenameB) return -1
      if (filenameA > filenameB) return 1
      return 0
    })
    let letters = allLetters.slice(0, 3)
    return (
      <div className="ml-3 flex h-5">
        {letters.map((letter:any,index:number) => {
          return (
            <img
            key={index}
              className="mx-0.5 w-5"
              src={`${spaceBaseUrl}/alphabet-preview-public${letter.file.fpath}${letter.file.preview}`}
            />
          )
        })}
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center">
          <div
            onClick={() => setExpand(!expand)}
            className="cursor-pointer flex flex-row items-center text-xl font-extrabold"
            style={{ width: "fit-content" }}
          >
            <div
              className={`${
                file.type === "dir" ? "folder" : "file"
              } flex flex-row justify-center items-center w-10 h-10 rounded-md mr-0 `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-folder"
                viewBox="0 0 20 20"
              >
                <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z" />
              </svg>
            </div>
            <div style={{}} className="flex  items-center">
              {name.includes("Alphabet") ? (
                <>
                  <div className="w-fit">
                    {`Style`} {name.split(" ")[1]}
                  </div>{" "}
                  <PreviewLetters />
                </>
              ) : (
                name.replaceAll("_", "-")
              )}
            </div>
          </div>
        </div>
      </div>
      {expand && (
        <div className="ml-8">
          {file.children.map((file: any, idx: number) => {
            return (
              <Folder
                key={idx}
                file={file}
                name={file.name.replaceAll("_", "-")}
                count={count}
                folderNames={folderNames}
              />
            )
          })}
          <div className="grid grid-cols-3 gap-3">
            {file.files.map((file: any) => {
              const previewURL = spaceBaseUrl + "/alphabet-preview-public" + file.file.fpath + file.file.preview

              const imageUrl = file.file.fpath + "/" + file.file.filename
              return (
                <Block key={file.file.id} className="flex-shrink-0 cursor-pointer">
                  <LazyLoadImage
                    onClick={() => addObject(imageUrl)}
                    className=" h-20 p-1 aspect-square max-h-full max-w-full object-contain "
                    threshold={50}
                    alt={file.file.filename}
                    effect="blur"
                    src={previewURL}
                  />
                </Block>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default function () {
  const scenes = useDesignEditorPages()
  const imageAssetsData = useSelector(selectLettersData)
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const dispatch = useDispatch()
  const editor = useEditor()

  const [isLoading, setIsLoading] = React.useState(false)
  const [folders, setFolders] = React.useState<any>([])
  const [searchKey, setSearchKey] = useState<any>("")

  const [allImages, setAllImages] = useState<any[]>([])

  const [filteredImage, setFilteredImage] = useState<any[]>([])
  const [folderNames, setFolderNames] = useState<any[]>([])
  const { setScenes, setCurrentScene, currentScene, setCurrentDesign, currentDesign } =
    React.useContext(DesignEditorContext)
  const makeSearch = () => {
    if (searchKey.length < 2) return

    const minLength = searchKey.length - 1
    const options = {
      // isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      minMatchCharLength: minLength < 1 ? 1 : minLength,
      // location: 0,
      // threshold: 0.6,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      keys: ["fpath"],
    }

    const fuse = new Fuse(allImages, options)
    const filteredData = fuse.search(searchKey).map((item) => item.item)

    // var filteredData = allImages.filter(function (obj) {
    //   return obj["fpath"].search(new RegExp(searchKey, "i")) !== -1
    // })
    //  setSubTotalImages(perPage)
    generateAllFiles(filteredData, false)
    //  setImages(filteredData.slice(0, perPage))
    // setPageNumber(1)
    // setIsloading(true)
    // fetchData(true)
  }
  const generateAllFiles = (all: any, isShortEnabled = true) => {
    if (all.length === 0) return
    let root = convertFilesToFolderStructure(all)[0].children
    if (isShortEnabled) {
      // const sortedObj = Object.fromEntries(Object.entries(root).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)))
      let sortedObj = root.sort((a: any, b: any) => {
        const filenameA = a.name.toLowerCase()
        const filenameB = b.name.toLowerCase()
        const partsA = filenameA.split(/(\d+)/)
        const partsB = filenameB.split(/(\d+)/)
        for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
          if (partsA[i] === undefined) return -1
          if (partsB[i] === undefined) return 1

          const partA = partsA[i]
          const partB = partsB[i]

          if (!isNaN(partA) && !isNaN(partB)) {
            const numA = parseInt(partA)
            const numB = parseInt(partB)
            if (numA < numB) return -1
            if (numA > numB) return 1
          } else {
            if (partA < partB) return -1
            if (partA > partB) return 1
          }
        }
        return 0
      })
      setFolders(sortedObj)
    } else {
      setFolders(root)
    }

    setIsLoading(false)
  }
  const syncData = async ({ updatedScenes }: { updatedScenes: IScene[] }) => {
    const token = sessionStorage.getItem("token")
    if (token) {
      const updatedPages = updatedScenes.map((p) => {
        // const { ["preview"]: _, ...rest } = p
        let rest = p
        return rest
      })

      syncDataByUserId({
        token,
        data: updatedPages,
      })
        .then((res) => {})
        .catch((err) => {
          console.error(err)
          dispatch(
            openSnackBar({
              title: "Error syncing scenes",
              message: "Unable to sync scenes",
              KIND: "error",
            })
          )
        })
    }
  }

  useEffect(() => {
    if (searchKey === "") {
      setIsLoading(true)
      generateAllFiles(allImages)
      setFilteredImage([])
      setIsLoading(false)
    }
  }, [searchKey])

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      let all
      if (imageAssetsData.length == 0) {
        all = await getAllDigitalAssetsImages()
        //@ts-ignore
        dispatch(setLettersData(all))
      } else {
        all = imageAssetsData
      }
      setAllImages(all)
      generateAllFiles(all)
    })()
    return () => {
      const updateScenesAndSyncData = async () => {
        //@ts-ignore
        let UpdatedCurrentScenePreview = await editor.renderer.render(currentScene)
        let _updatedScenes = scenes
        _updatedScenes.forEach((element) => {
          if (element.id === currentScene?.id) {
            //@ts-ignore
            element.preview = UpdatedCurrentScenePreview
          }
        })
        syncData({ updatedScenes: _updatedScenes })
      }
      updateScenesAndSyncData()
    }
  }, [])

  return (
    <Block $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Block
        $style={{
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          justifyContent: "space-between",
          padding: "1.5rem 1.5rem 0",
        }}
      >
        <Block className="font-primary">Letters & Numbers</Block>

        <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
          <AngleDoubleLeft size={18} />
        </Block>
      </Block>
      <Block $style={{ padding: "1.5rem 1.5rem 1rem" }}>
        <Input
          overrides={{
            Root: {
              style: {
                paddingLeft: "8px",
              },
            },
          }}
          onKeyDown={(key) => key.code === "Enter" && makeSearch()}
          onBlur={makeSearch}
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          placeholder="Search"
          size={"compact"}
          endEnhancer={
            <div className="cursor-pointer shadow-2xl">
              <Search size={16} />
            </div>
          }
        />
      </Block>
      <Scrollable>
        <div className="flex flex-row justify-center text-center w-full">
          {isLoading && <Spinner $size={SIZE.small} className="m-auto" />}
        </div>
        <div className="mx-2">
          {Object.keys(folders).map((folder: any, index) => {
            return (
              <Folder
                key={index}
                file={folders[folder]}
                name={folders[folder].name.replaceAll("_", "-")}
                folderNames={folderNames}
              />
            )
          })}
        </div>
        {/* Folder not found */}
        {Object.keys(folders).length === 0 && (
          <div className="flex flex-col justify-center items-center">
            <div className="flex flex-col justify-center items-center">
              {/* <img src={NoData} alt="No Data" className="w-32" /> */}
              <div className="text-gray-500">No Data Found</div>
            </div>
          </div>
        )}
      </Scrollable>
    </Block>
  )
}
