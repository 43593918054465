import icon from "../../../public/images/icons/brand_logo.png"

function Logo({ size }: { size: number }) {
  return (
    <img src={icon} height={size} />
    // <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" height={size}>
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M10.7702 2.27665C11.5444 1.88955 12.4557 1.88955 13.2299 2.27665L20.5466 5.93501C21.8364 6.57993 21.8364 8.42059 20.5466 9.0655L13.2299 12.7238C12.4557 13.1109 11.5444 13.1109 10.7702 12.7238L3.45346 9.0655C2.16362 8.42058 2.16363 6.57992 3.45346 5.93501L10.7702 2.27665ZM2.31458 17.1955C2.48281 16.817 2.92603 16.6466 3.30455 16.8148L11.4923 20.4538C11.8155 20.5974 12.1844 20.5974 12.5076 20.4538L20.6953 16.8148C21.0739 16.6466 21.5171 16.817 21.6853 17.1955C21.8535 17.5741 21.6831 18.0173 21.3045 18.1855L13.1168 21.8245C12.4058 22.1405 11.5941 22.1405 10.8831 21.8245L2.69534 18.1855C2.31683 18.0173 2.14636 17.5741 2.31458 17.1955V17.1955ZM3.30455 12.3148C2.92603 12.1466 2.48281 12.317 2.31458 12.6955C2.14636 13.0741 2.31683 13.5173 2.69534 13.6855L10.8831 17.3245C11.5941 17.6405 12.4058 17.6405 13.1168 17.3245L21.3045 13.6855C21.6831 13.5173 21.8535 13.0741 21.6853 12.6955C21.5171 12.317 21.0739 12.1466 20.6953 12.3148L12.5076 15.9538C12.1844 16.0974 11.8155 16.0974 11.4923 15.9538L3.30455 12.3148Z"
    //     fill="currentColor"
    //   />
    // </svg>
  )
}

export default Logo
