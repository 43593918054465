function Locked({ size }: { size: number }) {
  return (
    <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.75 9V8.25C16.75 6.85761 16.1969 5.52226 15.2123 4.53769C14.2277 3.55312 12.8924 3 11.5 3C10.1076 3 8.77226 3.55312 7.78769 4.53769C6.80312 5.52226 6.25 6.85761 6.25 8.25V9H4V18.75C4 19.3467 4.23705 19.919 4.65901 20.341C5.08097 20.7629 5.65326 21 6.25 21H16.75C17.3467 21 17.919 20.7629 18.341 20.341C18.7629 19.919 19 19.3467 19 18.75V9H16.75ZM7.75 8.25C7.75 7.25544 8.14509 6.30161 8.84835 5.59835C9.55161 4.89509 10.5054 4.5 11.5 4.5C12.4946 4.5 13.4484 4.89509 14.1517 5.59835C14.8549 6.30161 15.25 7.25544 15.25 8.25V9H7.75V8.25ZM17.5 18.75C17.5 18.9489 17.421 19.1397 17.2803 19.2803C17.1397 19.421 16.9489 19.5 16.75 19.5H6.25C6.05109 19.5 5.86032 19.421 5.71967 19.2803C5.57902 19.1397 5.5 18.9489 5.5 18.75V10.5H17.5V18.75Z"
        fill="currentColor"
      />
      <path d="M12.25 13.5H10.75V16.5H12.25V13.5Z" fill="currentColor" />
    </svg>
  )
}

export default Locked
