import axios from "axios"

const api = axios.create({
  baseURL: import.meta.env.VITE_SERVER_URL,
})

export interface ILoginResponse {
  token?: string
  Error?: string
}

export interface ILogin {
  username: string
  password: string
}

// https://api.selfpublishingtitans.com/api/account/login
// {
//     token: string
// }
// Method: POST
export const login = ({ username, password }: ILogin): Promise<ILoginResponse> => {
  return new Promise((resolve, reject) => {
    api
      .post(`/api/account/login`, {
        username,
        password,
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => reject(err))
  })
}
