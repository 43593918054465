import { createReducer } from "@reduxjs/toolkit"
import { IAuthenticationState, IIsOwner, setAuth, setOwner } from "./actions"

const initialState: IAuthenticationState = {
  isAuthenticated: false,
  user: null,
  token: null,
}

const isOwnerInitialState: IIsOwner = {
  isOwner: false,
  isDirty: false,
}

export const authReducer = createReducer(initialState, (builder) => {
  builder.addCase(setAuth, (state) => {
    const token = sessionStorage.getItem("token")
    state.token = token
    if (token) {
      state.isAuthenticated = true
    }
  })
})

export const isOwnerReducer = createReducer(isOwnerInitialState, (builder) => {
  builder.addCase(setOwner, (state, { payload }) => {
    state.isOwner = payload.isOwner
    state.isDirty = payload.isDirty
  })
})
