import React, { useEffect, useState, useRef } from "react"
import { useEditor, useActiveObject } from "@layerhub-io/react"
import { Block } from "baseui/block"
import { RgbColorPicker } from "react-colorful"
import Scrollable from "~/components/Scrollable"
import InfiniteScrolling from "~/components/InfiniteScrolling"
import Search from "~/components/Icons/Search"
import { Input } from "baseui/input"
import { LazyLoadImage, trackWindowScroll } from "react-lazy-load-image-component"
import { SIZE, Spinner } from "baseui/spinner"
import AngleDoubleLeft from "~/components/Icons/AngleDoubleLeft"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import { v4 as uuidv4 } from "uuid"
import Fuse from "fuse.js"
import { useDispatch, useSelector } from "react-redux"
import { isOwnerSelector } from "~/store/slices/auth/selectors"
import { themes } from "../../../../../../data/images/images"
import uploadIcon from "../../../../../../data/images/icons/upload.png"
import { setLoadingStatus } from "~/store/slices/loading-status/actions"
import useDesignEditorPages from "~/hooks/useDesignEditorScenes"
import { getPixabayImages } from "~/services/pixabay"
import { DesignEditorContext } from "~/contexts/DesignEditor"
import { isHttpUrl } from "~/utils/types"
import backgroundReplacementWorkerScript from "~/utils/Workers/backgroundReplacementWorker"
import backgroundColorScript from "~/utils/Workers/backgroundColorWorker"
import { generateBlobUrl, getCanvasObjectTemplate } from "~/utils/common"
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox"
import ThemeGif from "../../../../../../public/gif/gif1.gif"
import { uploadedThemesClassDB } from "~/indexDB/db"
import { toBase64 } from "~/utils/data"
import deleteIcon from "../../../../../../public/icons/delete.svg"
import { StatefulTooltip, PLACEMENT, TRIGGER_TYPE } from "baseui/tooltip"
import borderColorScript from "~/utils/Workers/borderColorWorker"
import Collapsible from "~/components/Collapse/Collapse"
import { getSyncDataByUserId, syncDataByUserId } from "~/services/digital-assets"
import { IScene } from "@layerhub-io/types"
import { openSnackBar } from "~/store/slices/snackbar/actions"

const themesComponent = () => {
  const editor = useEditor()
  const [hasMore, setHasMore] = React.useState(true)
  const [images, setImages] = useState<any[]>([])
  const dispatch = useDispatch()
  const [filteredImage, setFilteredImage] = useState<any[]>([])
  const [allImages, setAllImages] = useState<any[]>([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [searchKey, setSearchKey] = useState<string>("")
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const perPage = 12
  const [subTotalImages, setSubTotalImages] = React.useState(0)
  const activeObject: any = useActiveObject()
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [showUploadIcon, setShowUploadIcon] = useState(true)
  const [checked, setChecked] = useState(false)
  const [showBorder, setShowBorder] = useState(true)
  const [activeKey, setActiveKey] = React.useState("0")
  let [borderColor, setBorderColor] = useState({ r: 0, g: 0, b: 0 })
  
  let scenes = useDesignEditorPages()
  const { setScenes, setCurrentScene, currentScene, setCurrentDesign, currentDesign } =
    React.useContext(DesignEditorContext)

    function increaseNumbers(set1:any, set2:any, targetA:number) {
      let factor = Math.max(set1[0] / set2[0], set1[1] / set2[1]);
      set2[0] *= factor;
      set2[1] *= factor;
    
      return set2;
    }
  const PRESET_COLORS = [
    "#f44336",
    "#ff9800",
    "#ffee58",
    "#66bb6a",
    "#26a69a",
    "#03a9f4",
    "#3f51b5",
    "#673ab7",
    "#9c27b0",
    "#ec407a",
    "#8d6e63",
    "#d9d9d9",
  ]
  function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  }
  useEffect(() => {
    if (searchKey == "") {
      setImages(getThemeImages())
      setShowUploadIcon(true)
    }
  }, [searchKey])

  //to decide single or bulk update
  async function handleBackgroundChange(bgImagePath: string) {
    if (checked) {
      //if checked apply to all pages
      changeBulkBackgrounds(bgImagePath)
    } else {
      replaceImageBg(bgImagePath)
    }
  }

  const syncData = async ({ updatedScenes }: { updatedScenes: IScene[] }) => {
    const token = sessionStorage.getItem("token")
    if (token) {
      const updatedPages = updatedScenes.map((p) => {
        // const { ["preview"]: _, ...rest } = p  
        let rest = p
        return rest
      })

      syncDataByUserId({
        token,
        data: updatedPages,
      })
        .then((res) => {})
        .catch((err) => {
          console.error(err)
          dispatch(
            openSnackBar({
              title: "Error syncing scenes",
              message: "Unable to sync scenes",
              KIND: "error",
            })
          )
        })
    }
  }

  async function applyBorderColor(imagePath: any) {
    if (!showBorder) return alert("Please enable show border first")
    const startTime = window.performance.now()
    if (!activeObject || activeObject.name == "Initial Frame") {
      alert("Please Select an Object")
      return
    }
    if (activeObject._objects) {
      let objs = activeObject._objects
      let arr: any = []
      objs.forEach((imageObj: any) => {
        arr.push(imageObj.getSrc())
      })
      return
      //left for future implementation
    }

    let currObj = getActiveImageProperties()
    const fgImage = new Image()
    const bgImage = new Image()

    bgImage.src = await generateBlobUrl(activeObject.getSrc())
    let letterImageSrc 
    if(activeObject.metadata.ogSrc){

      letterImageSrc = activeObject.metadata.ogSrc
    }else{
      letterImageSrc = activeObject.getSrc()
      activeObject.metadata.ogSrc = letterImageSrc
    }
    fgImage.src = await generateBlobUrl(letterImageSrc)

    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    canvas.width = currObj.width
    canvas.height = currObj.height

    fgImage.onload = async function () {
      let fgProps = [fgImage.width, fgImage.height]
      let bgProps = [bgImage.width, bgImage.height]
      let backgroundAspectRatio = bgProps[0] / bgProps[1]
      let newDimensions = increaseNumbers(fgProps, bgProps, backgroundAspectRatio)
       const newBgWidth = newDimensions[0]
      const newBgHeight = newDimensions[1]
      const bgCanvas = document.createElement("canvas")
      bgCanvas.width = newBgWidth
      bgCanvas.height = newBgHeight
      const bgCtx = bgCanvas.getContext("2d")
      bgCtx?.drawImage(bgImage, 0, 0, newBgWidth, newBgHeight)
      let leftOffset = (newBgWidth - canvas.width) / 2
      if (leftOffset < 0) leftOffset = 0
      ctx?.drawImage(fgImage, 0, 0, canvas.width, canvas.height)
      const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height) ?? new ImageData(0, 0)
      const imageData2 = bgCtx?.getImageData(leftOffset, 0, canvas.width, canvas.height) ?? new ImageData(0, 0)

      let worker = new Worker(borderColorScript)
      let color = imagePath
      worker.postMessage({ imageData, imageData2, color, showBorder })
      let resultImageData = await new Promise((resolve) => {
        worker.onmessage = (e) => {
          resolve(e.data)
        }
      })
      //@ts-ignore
      ctx?.putImageData(resultImageData, 0, 0)
      const resultImageDataURL = canvas.toDataURL()
      activeObject.setSrc(resultImageDataURL, (img: any) => {
        editor.history.save()
        editor.cancelContextMenuRequest()
        editor.canvas.requestRenderAll()
      })

      const endTime = window.performance.now()
      console.log("TIME TAKEN", endTime - startTime)
    }
  }

  //to apply color to letter images
  async function applyColorToLetter(color: any) {
    if (checked) return
    const startTime = window.performance.now()
    if (!activeObject || activeObject.name == "Initial Frame") {
      alert("Please Select an Object")
      return
    }
    if (activeObject._objects) {
      let objs = activeObject._objects
      let arr: any = []
      objs.forEach((imageObj: any) => {
        arr.push(imageObj.getSrc())
      })
      return
      //left for future implementation
    }
    let currObj = getActiveImageProperties()
    const fgImage = new Image()
    let letterImageSrc 
    if(activeObject.metadata.ogSrc){

      letterImageSrc = activeObject.metadata.ogSrc
    }else{
      letterImageSrc = activeObject.getSrc()
      activeObject.metadata.ogSrc = letterImageSrc
    }
    fgImage.src = await generateBlobUrl(letterImageSrc)
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    canvas.width = currObj.width
    canvas.height = currObj.height
    fgImage.onload = async function () {
      ctx?.drawImage(fgImage, 0, 0, canvas.width, canvas.height)
      const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height) ?? new ImageData(0, 0)

      let worker = new Worker(backgroundColorScript)
      worker.postMessage({ imageData, color, showBorder, borderColor })
      let resultImageData = await new Promise((resolve) => {
        worker.onmessage = (e) => {
          resolve(e.data)
        }
      })
      //@ts-ignore
      ctx?.putImageData(resultImageData, 0, 0)
      const resultImageDataURL = canvas.toDataURL()
      activeObject.setSrc(resultImageDataURL, (img: any) => {
        editor.history.save()
        editor.cancelContextMenuRequest()
        editor.canvas.requestRenderAll()
      })

      const endTime = window.performance.now()
      console.log("TIME TAKEN", endTime - startTime)
    }
  }

  //changes letter images backgrounds of a supplied layer
  async function changeBackground(layer: any, imagePath: any) {
    return new Promise(async (resolve, reject) => {
      const fgImage = new Image()
      const bgImage = new Image()

      if (isHttpUrl(imagePath)) {
        bgImage.src = await generateBlobUrl(imagePath)
      } else {
        bgImage.src = imagePath
      }
      let letterImageSrc 
      if(layer.metadata?.ogSrc){
        letterImageSrc = layer.metadata.ogSrc
      }else{
        letterImageSrc = layer.src
        layer["metadata"] = layer["metadata"] ?? { ogSrc: letterImageSrc }
        // layer.metadata.ogSrc = letterImageSrc
      }
      fgImage.src = await generateBlobUrl(letterImageSrc)

      // fgImage.src = await generateBlobUrl(layer.metadata.ogSrc)

      const canvas = document.createElement("canvas")
      const ctx = canvas.getContext("2d")
      canvas.width = layer.width
      canvas.height = layer.height
      fgImage.onload = async function () {
        let fgProps = [fgImage.width, fgImage.height]
        let bgProps = [bgImage.width, bgImage.height]
        let backgroundAspectRatio = bgProps[0] / bgProps[1]
        let newDimensions = increaseNumbers(fgProps, bgProps, backgroundAspectRatio)
         const newBgWidth = newDimensions[0]
        const newBgHeight = newDimensions[1]
        const bgCanvas = document.createElement("canvas")
        bgCanvas.width = newBgWidth
        bgCanvas.height = newBgHeight
        const bgCtx = bgCanvas.getContext("2d")
        bgCtx?.drawImage(bgImage, 0, 0, newBgWidth, newBgHeight)
        let leftOffset = (newBgWidth - canvas.width) / 2
        if (leftOffset < 0) leftOffset = 0
        ctx?.drawImage(fgImage, 0, 0, canvas.width, canvas.height)
        const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height) ?? new ImageData(0, 0)
        const imageData2 = bgCtx?.getImageData(leftOffset, 0, canvas.width, canvas.height) ?? new ImageData(0, 0)
        let worker = new Worker(backgroundReplacementWorkerScript)
        worker.postMessage({ imageData, imageData2, showBorder, borderColor })
        worker.onmessage = (e) => {
          let resultImage = e.data
          ctx?.putImageData(resultImage, 0, 0)
          const resultImageDataURL = canvas.toDataURL()
          layer.src = resultImageDataURL
          resolve(layer)
        }
      }
    })
  }

  //iterating change of background over each layer
  async function changeBulkBackgrounds(imagePath: string) {
    let tempScenes: any = []
    scenes.forEach((scene: any, index) => {
      tempScenes.push({ ...scene, ind: index })
    })
    dispatch(
      setLoadingStatus({
        isLoading: true,
        title: "Replacing Element's Background...",
      })
    )
    let modifiedScenes: any = []
    await Promise.all(
      tempScenes.map(async (scene: any) => {
        let layers = scene.layers
        let modifiedLayers = [layers[0]]
        if (layers.length > 1) {
          let letterLayers = layers.slice(1, layers.length)
          await Promise.all(
            letterLayers.map(async (layer: any) => {
              let newLayer = await changeBackground(layer, imagePath)
              modifiedLayers.push(newLayer)
            })
          )
        }
        modifiedScenes.push({ ...scene, layers: modifiedLayers })
      })
    )
    let finalScenes: any = await Promise.all(
      modifiedScenes.map(async (scene: any) => {
        let preview = await editor.renderer.render(scene)
        return { ...scene, preview }
      })
    )

    finalScenes.sort((a: any, b: any) => a.ind - b.ind)
    let newScenes = finalScenes.map((obj: any) => {
      const { ind, ...newObj } = obj
      return newObj
    })

    setScenes(newScenes)
    setCurrentScene(finalScenes[finalScenes.length - 1])
    syncData({ updatedScenes: newScenes })
    dispatch(
      setLoadingStatus({
        isLoading: false,
        title: "",
      })
    )
  }

  function getThemeImages() {
    return themes.map((theme) => {
      return {
        height: 1000,
        width: 1000,
        id: uuidv4(),
        fpath: `${theme}`,
        filename: theme,
        preview: theme,
      }
    })
  }

  function getActiveImageProperties() {
    return {
      height: activeObject.height,
      width: activeObject.width,
      src: activeObject.getSrc(),
    }
  }

  async function replaceImageBg(imagePath: string) {
    const startTime = window.performance.now()
    if (!activeObject || activeObject.name == "Initial Frame") {
      alert("Please Select an Object")
      return
    }
    if (activeObject._objects) {
      let objs = activeObject._objects
      let arr: any = []
      objs.forEach((imageObj: any) => {
        arr.push(imageObj.getSrc())
      })
      return
      //left for future implementation
    }

    let currObj = getActiveImageProperties()
    const fgImage = new Image()
    const bgImage = new Image()

    if (isHttpUrl(imagePath)) {
      bgImage.src = await generateBlobUrl(imagePath)
    } else {
      bgImage.src = imagePath
    }
    let letterImageSrc 
    if(activeObject.metadata.ogSrc){

      letterImageSrc = activeObject.metadata.ogSrc
    }else{
      letterImageSrc = activeObject.getSrc()
      activeObject.metadata.ogSrc = letterImageSrc
    }
    fgImage.src = await generateBlobUrl(letterImageSrc)

    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    canvas.width = currObj.width
    canvas.height = currObj.height

    fgImage.onload = async function () {
      let fgProps = [fgImage.width, fgImage.height]
      let bgProps = [bgImage.width, bgImage.height]
      let backgroundAspectRatio = bgProps[0] / bgProps[1]
      let newDimensions = increaseNumbers(fgProps, bgProps, backgroundAspectRatio)
       const newBgWidth = newDimensions[0]
      const newBgHeight = newDimensions[1]
      const bgCanvas = document.createElement("canvas")
      bgCanvas.width = newBgWidth
      bgCanvas.height = newBgHeight
      const bgCtx = bgCanvas.getContext("2d")
      bgCtx?.drawImage(bgImage, 0, 0, newBgWidth, newBgHeight)
      let leftOffset = (newBgWidth - canvas.width) / 2
      if (leftOffset < 0) leftOffset = 0
      ctx?.drawImage(fgImage, 0, 0, canvas.width, canvas.height)
      const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height) ?? new ImageData(0, 0)
      const imageData2 = bgCtx?.getImageData(leftOffset, 0, canvas.width, canvas.height) ?? new ImageData(0, 0)

      let worker = new Worker(backgroundReplacementWorkerScript)
      worker.postMessage({ imageData, imageData2, showBorder, borderColor })
      let resultImageData = await new Promise((resolve) => {
        worker.onmessage = (e) => {
          resolve(e.data)
        }
      })
      //@ts-ignore
      ctx?.putImageData(resultImageData, 0, 0)
      const resultImageDataURL = canvas.toDataURL()
      activeObject.setSrc(resultImageDataURL, (img: any) => {
        editor.history.save()
        editor.cancelContextMenuRequest()
        editor.canvas.requestRenderAll()
      })

      const endTime = window.performance.now()
      console.log("TIME TAKEN", endTime - startTime)
    }
  }
  const isOwner = useSelector(isOwnerSelector).isOwner

  const addMore = React.useCallback(() => {
    if (allImages.length === 0) return
    if (filteredImage.length) {
      if (filteredImage.length > images.length) {
        setIsLoading(true)
        const currentTotal = subTotalImages + perPage
        setSubTotalImages(currentTotal)
        setImages(filteredImage.slice(0, currentTotal))
        setIsLoading(false)
      } else {
        setHasMore(false)
      }
    } else {
      if (allImages.length > images.length) {
        setIsLoading(true)
        const currentTotal = subTotalImages + perPage
        setSubTotalImages(currentTotal)
        setImages(allImages.slice(0, currentTotal))
        setIsLoading(false)
      } else {
        setHasMore(false)
      }
    }
  }, [hasMore, allImages, images])

  const handleFileSelection = async (event: any) => {
    const file = event.target.files[0]
    if (file) {
      const url = (await toBase64(file)) as string
      let imgObj = {
        fpath: url,
        filename: file.name,
        width: 1000,
        height: 1000,
        id: uuidv4(),
        preview: url,
        sourceType: "Local",
      }
      setImages([imgObj, ...images])

      const prevImages = await uploadedThemesClassDB.themes.get(1)
      if (prevImages !== undefined) {
        await uploadedThemesClassDB.themes.update(1, {
          images: [...prevImages.images, imgObj],
          createdAt: new Date(),
        })
      } else {
        await uploadedThemesClassDB.themes.add({
          id: 1,
          images: [imgObj],
          createdAt: new Date(),
        })
      }
    }
  }

  const deleteAll = async () => {
    let staticThemes: any = []
    images.forEach((i) => {
      if (i.sourceType !== "Local") {
        staticThemes.push(i)
      }
    })
    setImages(staticThemes)
    await uploadedThemesClassDB.themes.clear()
  }

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      const all = await getThemeImages()
      const options = {
        keys: ["filename"],
      }
      let storedImages: any = []
      const fuse = new Fuse(all, options)
      const filteredData = fuse.search("Halloween").map((item) => item.item)
      const data = [...new Set([...filteredData, ...all])]
      await uploadedThemesClassDB.themes.get(1).then((themesData) => {
        if (themesData) {
          setAllImages([...themesData.images, ...data])
        } else {
          setAllImages([...data])
        }
      })
      setSubTotalImages(perPage)
      setImages([...data.slice(0, perPage)])
      setIsLoading(false)
    })()
  }, [])

  async function searchPixaBay() {
    setIsLoading(true)
    setShowUploadIcon(false)
    let query = searchKey.split(" ").join("+")
    const res = await getPixabayImages(query)
    let imageUrls = res.map((image: any) => getCanvasObjectTemplate(image))
    setImages(imageUrls)
    setIsLoading(false)
  }
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }
  const imageSource = isHovered ? ThemeGif : uploadIcon
  return (
    <Block flex={1} flexDirection="column" display={"flex"}>
      <Block
        $style={{
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          justifyContent: "space-between",
          padding: "1.5rem 1.5rem 0",
        }}
      >
        <Block className=" font-primary">Element Themes</Block>

        <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
          <AngleDoubleLeft size={18} />
        </Block>
      </Block>

      <Block $style={{ padding: "1.5rem 1.5rem 1rem" }}>
        <Input
          overrides={{
            Root: {
              style: {
                paddingLeft: "8px",
              },
            },
          }}
          onKeyDown={(key) => key.code === "Enter" && searchPixaBay()}
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          placeholder="Search More on Pixabay"
          size={"compact"}
          endEnhancer={
            <div className="cursor-pointer shadow-2xl">
              <Search size={16} />
            </div>
          }
        />
      </Block>

      {showUploadIcon && (
        <Block className="flex justify-center items-center">
          <Block
            $style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px solid black",
              width: `${isHovered ? "175px" : "150px"}`,
            }}
            className="font-primary transition-all rounded-md my-0 h-10 "
            onClick={() => {
              if (fileInputRef.current) {
                fileInputRef.current.click()
              }
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className={`${isHovered ? "w-10 mr-3" : "w-5"} flex mr-1 transition-all justify-center items-center`}>
              <LazyLoadImage
                className={` transition-all `}
                style={{ height: "20px", marginRight: "0.5rem" }}
                alt={"upload image"}
                effect="blur"
                src={imageSource}
                onContextMenu={(e) => {
                  e.preventDefault()
                }}
              />
            </div>
            <p className="font-Montserrat  mr-1">Upload Image</p>
            <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileSelection} />
          </Block>

          <Block className=" w-fit font-primary text-xs text-center">
            <StatefulTooltip
              content={() => <Block>Delete Uploaded Themes</Block>}
              placement={PLACEMENT.top}
              returnFocus
              autoFocus
            >
              <img
                style={{ height: "20px" }}
                className="ml-2 cursor-pointer"
                onClick={() => deleteAll()}
                src={deleteIcon}
              />
            </StatefulTooltip>
          </Block>
        </Block>
      )}
      {showUploadIcon && (
        <Block className="rounded-md transition-all flex justify-center items-center font-mono mx-6 mt-2">
          <div className=" bg-blue-200 rounded-md px-2">
            <p className=" text-xs ">Note: upload backgrounds Only</p>
          </div>
        </Block>
      )}
      <Block $style={{ padding: "1.3rem 1.5rem 1rem" }}>
        <Checkbox
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
          labelPlacement={LABEL_PLACEMENT.right}
        >
          Apply To All Pages
        </Checkbox>
        <Block $style={{ paddingTop: "0.6rem", display: "flex", justifyContent: "space-between" }}>
          <Checkbox
            checked={showBorder}
            onChange={(e) => setShowBorder(e.target.checked)}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            Show Border
          </Checkbox>
        </Block>
      </Block>
      <Scrollable>
        <Block padding={"0 1.5rem"}>
          <InfiniteScrolling fetchData={addMore} hasMore={hasMore}>
            <Block
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "0.5rem",
              }}
            >
              {images.map((image) => {
                return (
                  <Block
                    $style={{ cursor: "pointer", width: "auto", height: "70px" }}
                    onClick={() => handleBackgroundChange(image.fpath)}
                    key={image.id}
                  >
                    <LazyLoadImage
                      className=" w-full h-full object-cover rounded-md"
                      alt={image.filename}
                      effect="blur"
                      src={image.fpath}
                      onContextMenu={(e) => {
                        e.preventDefault()
                      }}
                    />
                  </Block>
                )
              })}
              {images.length === 0 && !isLoading && (
                <Block
                  $style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  No images found
                </Block>
              )}
            </Block>
            <Block
              $style={{
                display: "flex",
                justifyContent: "center",
                paddingY: "2rem",
              }}
            >
              {isLoading && <Spinner $size={SIZE.small} />}
            </Block>
          </InfiniteScrolling>
        </Block>
        <Collapsible title="Letter Color">
          <Block padding="0 1.5rem" className=" mb-5">
            {/* <Block $style={{ padding: "0.25rem 0", fontWeight: 500, fontSize: "16px" }} className="font-primary">
              Color Palette
            </Block> */}
            <RgbColorPicker
              onChange={(color) => applyColorToLetter(color)}
              style={{ width: "100%", cursor: `${checked ? `not-allowed` : ""}` }}
              className="mt-3 rounded-md"
            />
            <Block>
              <Block $style={{ padding: "0.75rem 0", fontWeight: 500, fontSize: "14px" }} className="font-primary">
                Preset colors
              </Block>
              <Block $style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr", gap: "0.25rem" }}>
                {PRESET_COLORS.map((presetColor, index) => (
                  <Block
                    $style={{
                      cursor: "pointer",
                    }}
                    onClick={() => applyColorToLetter(hexToRgb(presetColor))}
                    backgroundColor={presetColor}
                    height="38px"
                    key={index}
                    className="rounded-md"
                  />
                ))}
              </Block>
            </Block>
          </Block>
        </Collapsible>
        <Collapsible title="Outline Color" className="transition-all">
          <Block padding="0 1.5rem" className=" mb-5 transition-all">
            <RgbColorPicker
              onChange={(color) => {
                setBorderColor(color)
                applyBorderColor(color)
              }}
              style={{ width: "100%", cursor: `${checked ? `not-allowed` : ""}` }}
              className="mt-3 rounded-md"
            />
            <Block>
              <Block $style={{ padding: "0.75rem 0", fontWeight: 500, fontSize: "14px" }} className="font-primary">
                Preset colors
              </Block>
              <Block $style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr", gap: "0.25rem" }}>
                {PRESET_COLORS.map((presetColor, index) => (
                  <Block
                    $style={{
                      cursor: "pointer",
                    }}
                    onClick={() => applyBorderColor(hexToRgb(presetColor))}
                    backgroundColor={presetColor}
                    height="38px"
                    key={index}
                    className="rounded-md"
                  />
                ))}
              </Block>
            </Block>
          </Block>
        </Collapsible>
      </Scrollable>
    </Block>
  )
}

export default trackWindowScroll(themesComponent)
