import React, { useEffect, useState } from "react"
import { useEditor } from "@layerhub-io/react"
import { Block } from "baseui/block"
import Scrollable from "~/components/Scrollable"
import AngleDoubleLeft from "~/components/Icons/AngleDoubleLeft"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import { getDigitalAssetsElements } from "~/services/digital-assets"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { SIZE, Spinner } from "baseui/spinner"
import "react-lazy-load-image-component/src/effects/blur.css"
import { v4 as uuidv4 } from "uuid"
import { Input } from "baseui/input"
import Search from "~/components/Icons/Search"
import Fuse from "fuse.js"
import { useDispatch, useSelector } from "react-redux"
import { isOwnerSelector } from "~/store/slices/auth/selectors"
import { setElementsData } from "~/store/slices/assets/actions"
import { selectElementsData } from "~/store/slices/assets/selectors"
const spaceBaseUrl = "https://booksbytitans-bucket.sgp1.digitaloceanspaces.com"

const convertFilesToFolderStructure = (files: any) => {
  const folderSystem: any = []

  files.forEach((file: any) => {
    const pathComponents = file.fpath.split("/")
    let currentLevel = folderSystem
    pathComponents.forEach((component: any, index: number) => {
      const isLast = index === pathComponents.length - 1
      const existingItem = currentLevel.find((item: any) => item.name === component)
      if (existingItem) {
        if (isLast) {
          existingItem.files.push({
            type: "file",
            file: file,
          })
        } else {
          currentLevel = existingItem.children
        }
      } else {
        const newItem = {
          type: isLast ? "file" : "dir",
          name: component,
          children: [],
          files: isLast ? [{ type: "file", file: file }] : [],
        }

        currentLevel.push(newItem)

        if (!isLast) {
          currentLevel = newItem.children
        }
      }
    })
  })
  return folderSystem
}

function Folder({ name, file, count = 0, folderNames = [] }: any) {
  const isOwner = useSelector(isOwnerSelector).isOwner
  count++
  const [expand, setExpand] = useState(false)
  const editor = useEditor()
  const addObject = React.useCallback(
    (url: string) => {
      if (!isOwner) {
        alert("To add images to the canvas, one needs to purchase the Coloring Book Maker tool first.")
        return
      }
      const id = uuidv4()
      const finalUrl = spaceBaseUrl + "/coloring-book-maker-assets-public" + url
      const options = {
        id: id,
        type: "StaticImage",
        src: finalUrl,
        metadata: { ogSrc: finalUrl },
      }
      editor.objects.add(options).then(() => {
        editor.objects.scale("fit", id)
      })
    },
    [editor, isOwner]
  )
  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center">
          <div
            onClick={() => setExpand(!expand)}
            className="cursor-pointer flex flex-row items-center text-xl font-extrabold"
            style={{ width: "fit-content" }}
          >
            <div
              className={`${
                file.type === "dir" ? "folder" : "file"
              } flex flex-row justify-center items-center w-10 h-10 rounded-md mr-0 `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-folder"
                viewBox="0 0 20 20"
              >
                <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z" />
              </svg>
            </div>
            <div>{name.replaceAll("_", "-")}</div>
          </div>
        </div>
      </div>
      {expand && (
        <div className="ml-8">
          {file.children.map((file: any, idx: number) => {
            return (
              <Folder
                key={idx}
                file={file}
                name={file.name.replaceAll("_", "-")}
                count={count}
                folderNames={folderNames}
              />
            )
          })}
          <div className="grid grid-cols-2">
            {file.files.map((file: any) => {
              const previewURL = spaceBaseUrl + "/coloring-book-maker-preview/preview" + "/" + file.file.preview

              const imageUrl = file.file.fpath + "/" + file.file.filename

              return (
                <Block key={file.file.id} className="flex-shrink-0 cursor-pointer">
                  <LazyLoadImage
                    onClick={() => addObject(imageUrl)}
                    className="aspect-square h-20"
                    threshold={50}
                    alt={file.file.filename}
                    effect="blur"
                    src={previewURL}
                  />
                </Block>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default function () {
  const dispatch = useDispatch()
  const coloringElementsData = useSelector(selectElementsData)
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const [isLoading, setIsLoading] = React.useState(false)
  const [folders, setFolders] = React.useState<any>([])
  const [searchKey, setSearchKey] = useState<any>("")
  const [allImages, setAllImages] = useState<any[]>([])
  const [filteredImage, setFilteredImage] = useState<any[]>([])
  const [folderNames, setFolderNames] = useState<any[]>([])

  const makeSearch = () => {
    if (searchKey.length < 2) return
    const minLength = searchKey.length - 1
    const options = {
      minMatchCharLength: minLength < 1 ? 1 : minLength,
      keys: ["fpath"],
    }
    const fuse = new Fuse(allImages, options)
    const filteredData = fuse.search(searchKey).map((item) => item.item)
    generateAllFiles(filteredData, false)
  }

  const generateAllFiles = (all: any, isShortEnabled = true) => {
    if (all.length === 0) return
    let root = convertFilesToFolderStructure(all)[0].children
    if (isShortEnabled) {
      const sortedObj = Object.fromEntries(Object.entries(root).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)))
      setFolders(sortedObj)
    } else {
      setFolders(root)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (searchKey === "") {
      setIsLoading(true)
      generateAllFiles(allImages)
      setFilteredImage([])
      setIsLoading(false)
    }
  }, [searchKey])

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      let all
      if (coloringElementsData.length == 0) {
        all = await getDigitalAssetsElements()
        //@ts-ignore
        dispatch(setElementsData(all))
      } else {
        all = coloringElementsData
      }
      setAllImages(all)
      generateAllFiles(all)
    })()
  }, [])

  return (
    <Block $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Block
        $style={{
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          justifyContent: "space-between",
          padding: "1.5rem 1.5rem 0",
        }}
      >
        <Block>9,250 Elements</Block>

        <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
          <AngleDoubleLeft size={18} />
        </Block>
      </Block>
      <Block $style={{ padding: "1.5rem 1.5rem 1rem" }}>
        <Input
          overrides={{
            Root: {
              style: {
                paddingLeft: "8px",
              },
            },
          }}
          onKeyDown={(key) => key.code === "Enter" && makeSearch()}
          onBlur={makeSearch}
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          placeholder="Search"
          size={"compact"}
          endEnhancer={
            <div className="cursor-pointer shadow-2xl">
              <Search size={16} />
            </div>
          }
        />
      </Block>
      <Scrollable>
        <div className="flex flex-row justify-center text-center w-full">
          {isLoading && <Spinner $size={SIZE.small} className="m-auto" />}
        </div>
        <div className="mx-2">
          {Object.keys(folders).map((folder: any, index) => {
            return (
              <Folder
                key={index}
                file={folders[folder]}
                name={folders[folder].name.replaceAll("_", "-")}
                folderNames={folderNames}
              />
            )
          })}
        </div>

        {Object.keys(folders).length === 0 && (
          <div className="flex flex-col justify-center items-center">
            <div className="flex flex-col justify-center items-center">
              {/* <img src={NoData} alt="No Data" className="w-32" /> */}
              <div className="text-gray-500">No Data Found</div>
            </div>
          </div>
        )}
      </Scrollable>
    </Block>
  )
}
