interface IComBG {
  className: string;
  style?: object;
}

export default function ComBG({ className, style }: IComBG) {
  return (
    <div className={`${className} bg-gray-500`} style={{ ...style }}></div>
  );
}
