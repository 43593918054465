import { Page } from "~/interfaces/common"
import { createReducer } from "@reduxjs/toolkit"
import { setSize } from "./actions"
import { nanoid } from "nanoid"

interface FrameSizeState {
  currentSize: {
    id: number
    width: number
    height: number
  }
}

const initialState: FrameSizeState = {
  currentSize: {
    id: 15,
    width: 8.5,
    height: 11,
  },
}

export const currentFrameSizeReducer = createReducer(initialState, (builder) => {
  builder.addCase(setSize, (state, { payload }) => {
    state.currentSize = payload.currentSize
  })
  // builder.addCase(removePage, () => {})
})
